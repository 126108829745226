import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { getProduct } from "../../../helper/Producthelper";
import { Rating } from "../../../helper/helper";
import { Tab, Nav } from "react-bootstrap";
import Relatedproduct from "../../layouts/Relatedproductone";
import { useDispatch } from "../../../hooks/useRedux/useRedux";
import { cartSlice } from "../../../redux/cart/cart.slice";
import { toast } from "react-toastify";

export const Content = (props) => {
  const dispatch = useDispatch();
  const [size, setSize] = useState("12");
  const [qty, setQty] = useState(1);
  const productId = props.productId;
  const item = getProduct(productId);

  const notify = (item) => toast.success(`${item} added to cart`);

  const productID =
    item.sizes.length > 0
      ? item.sizes.find((item) => item.size === size).id
      : item.id;

  const productPrice =
    item.sizes.length > 0
      ? item.sizes.find((item) => item.size === size).price
      : item.price;

  const handleAddToCart = () => {
    notify(item.name);
    dispatch(
      cartSlice.actions.addToCart({
        id: productID,
        img: item.img,
        name: item.name,
        price: productPrice,
        size: item.sizes.length > 0 ? size : null,
        qty: qty,
        shortdesc: item.shortdesc,
        availableSizes: item.sizes,
      })
    );
  };

  const IncrementItem = () => {
    if (qty === 20) {
      setQty(20);
    } else {
      setQty(qty + 1);
    }
  };

  const DecreaseItem = () => {
    if (qty === 1) {
      setQty(1);
    } else {
      setQty(qty - 1);
    }
  };
  const handleChange = (event) => {
    setQty(event.target.value);
  };

  return (
    <Fragment>
      <div className="section product-single">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              {/* Main Thumb */}
              <div className="product-thumb">
                <img
                  src={process.env.PUBLIC_URL + "/" + item.img}
                  alt={item.name}
                />
              </div>
              {/* /Main Thumb */}
            </div>
            <div className="col-md-7">
              <div className="product-content">
                {/* Product Title */}
                <h2 className="title">{item.name}</h2>
                {/* /Product Title */}
                {/* Rating */}
                <div className="ct-rating-wrapper">
                  <div className="ct-rating">{Rating(item.rating)}</div>
                  <span>(24 ratings)</span>
                </div>
                {/* /Rating */}
                {/* Price */}
                <div className="price-wrapper">
                  <p className="product-price">
                    ${new Intl.NumberFormat().format(productPrice.toFixed(2))}
                  </p>
                </div>
                {/* /Price */}
                {/* Product Short Description */}
                <p>{item.shortdesc}</p>
                {/* /Product Short Description */}
                {/* Variations */}
                <div className="customize-variations">
                  {item.sizes.length > 0 && (
                    <div className="customize-size-wrapper">
                      <h5>Size: </h5>
                      {item.sizes.map((item, i) => (
                        <div className="customize-size-container">
                          <div
                            key={i}
                            className={
                              size === item.size
                                ? "customize-size active"
                                : "customize-size"
                            }
                            onClick={() => {
                              setSize(item.size);
                            }}
                          >
                            {item.size}"
                          </div>

                          {size === item.size && (
                            <p
                              className="subtitle"
                              style={{ marginBottom: "20px" }}
                            >
                              {size === "10"
                                ? "Small"
                                : size === "12"
                                ? "Medium"
                                : size === "14"
                                ? "Large"
                                : "X-Large"}
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="row">
                    {/* Variation Start 
                    {item.attributes.map((item, i) => (
                      <div key={i} className="col-lg-6 col-12">
                        <div className="customize-variation-wrapper">
                          <i className={item.icon} />
                          <h5>{item.name}</h5>
                          {item.items.map((add, i) => (
                            <div key={i} className="customize-variation-item">
                              <div
                                className={"custom-control custom-" + item.type}
                              >
                                <input
                                  type={item.type}
                                  id={add.title + i}
                                  name={item.name}
                                  className="custom-control-input"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={add.title + i}
                                >
                                  {add.title}
                                </label>
                              </div>
                              <span>
                                +
                                {new Intl.NumberFormat().format(
                                  add.addprice.toFixed(2)
                                )}
                                $
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                    {/* Variation End */}
                  </div>
                </div>
                {/* /Variations */}
                {/* Add To Cart Form */}
                <form className="atc-form" method="post">
                  <div className="form-group">
                    <label>Quantity</label>
                    <div className="qty">
                      <span className="qty-subtract" onClick={DecreaseItem}>
                        <i className="fa fa-minus" />
                      </span>
                      <input
                        type="text"
                        name="clicks"
                        value={qty}
                        onChange={() => handleChange.bind(this)}
                      />
                      <span className="qty-add" onClick={IncrementItem}>
                        <i className="fa fa-plus" />
                      </span>
                    </div>
                  </div>
                  <button
                    type="submit"
                    name="button"
                    className={`btn-custom`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleAddToCart();
                    }}
                  >
                    Add To Cart
                    <i className="fas fa-shopping-cart" />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section pt-0">
        <div className="container">
          {/* Additional Information Start */}
          <div className="product-additional-info">
            <Tab.Container defaultActiveKey="tab1">
              <Nav variant="tabs" className="nav">
                <Nav.Item>
                  <Nav.Link eventKey="tab1">Description</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab2">
                    Reviews ({item.reviews.length})
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="tab1">
                  <h4>Description</h4>
                  <div
                    dangerouslySetInnerHTML={{ __html: item.longdescription }}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="tab2">
                  <h4>Leave a Review</h4>
                  <div className="ct-rating-wrapper">
                    <div className="ct-rating">
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                      <i className="fas fa-star" />
                    </div>
                    <span>Your Review</span>
                  </div>
                  {/* Review Form start */}
                  <div className="comment-form">
                    <form method="post">
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Full Name"
                            name="fname"
                          />
                        </div>
                        <div className="col-md-6 form-group">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email Address"
                            name="email"
                          />
                        </div>
                        <div className="col-md-12 form-group">
                          <textarea
                            className="form-control"
                            placeholder="Type your comment..."
                            name="comment"
                            rows={7}
                          />
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn-custom primary"
                        name="button"
                      >
                        Post Review
                      </button>
                    </form>
                  </div>
                  {/* Review Form End */}
                  {/* Reviews Start */}
                  <div className="comments-list">
                    <ul>
                      {item.reviews.map((review, i) => (
                        <li key={i} className="comment-item">
                          <img
                            src={process.env.PUBLIC_URL + "/" + review.img}
                            alt={review.name}
                          />
                          <div className="comment-body">
                            <h5>{review.name}</h5>
                            <div className="ct-rating">
                              {Rating(item.rating)}
                            </div>
                            <span>Posted on: {review.date}</span>
                            <p>{review.comment}College in Virginia</p>
                            <Link to="#" className="reply-link">
                              {" "}
                              Reply{" "}
                            </Link>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {/* Reviews End */}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
          {/* Additional Information End */}
        </div>
      </div>
      <Relatedproduct />
    </Fragment>
  );
};
