import { Tab, Col, Nav, Row } from "react-bootstrap";
import { useAuth } from "../../../hooks/useAuth/useAuth";
import { useSignOutCustomer } from "./useSignOutCustomer";
import { usePhoneNumberFormatted } from "./usePhoneNumberFormatted";
import { DetailsItem } from "./DetailsItem";
import { useNavigate } from "react-router-dom";

export const Content = () => {
  const { user } = useAuth();
  const signOut = useSignOutCustomer();
  const phoneFormatted = usePhoneNumberFormatted(user?.phone);
  const navigate = useNavigate();

  return (
    <div className="section">
      <div className="container">
        <div className="d-flex flex-nowrap justify-content-between">
          <h2>My Profile</h2>
          <div className="d-flex align-items-center gap-1">
            <span
              style={{
                color: "#ED4E53",
                margin: "0px",
                textDecoration: "underline",
                lineHeight: "20px",
                cursor: "pointer",
                paddingRight: "10px",
              }}
              onClick={() => {
                signOut.mutate();
              }}
            >
              Logout
            </span>

            <button
              className="btn-custom primary"
              onClick={() => navigate("/edit-profile")}
            >
              Edit
            </button>
          </div>
        </div>
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="basicInformation"
        >
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="basicInformation">
                    Basic Information
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="orders">Orders</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="basicInformation">
                  <div className="flex-column">
                    <h4>Personal Information</h4>
                    <div className="row row-gap-1">
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <DetailsItem
                          name="First Name"
                          value={user?.firstName ?? "N/A"}
                        />
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <DetailsItem
                          name="Middle Name"
                          value={user?.middleName ?? "N/A"}
                        />
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <DetailsItem
                          name="Last Name"
                          value={user?.lastName ?? "N/A"}
                        />
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <DetailsItem
                          name="Email"
                          value={user?.email ?? "N/A"}
                        />
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <DetailsItem
                          name="Gender"
                          value={
                            user?.gender === "m"
                              ? "Male"
                              : user?.gender === "f"
                              ? "Female"
                              : "Undisclosed"
                          }
                        />
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <DetailsItem
                          name="Phone"
                          value={phoneFormatted ?? "N/A"}
                        />
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <DetailsItem
                          name="Date Of Birth"
                          value={user?.dateOfBirth ?? "N/A"}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="flex-column">
                    <h4>Street Address</h4>
                    <div className="row row-gap-1">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <DetailsItem
                          name="Address 1"
                          value={
                            user?.streetAddress?.address1 !== ""
                              ? user?.streetAddress?.address1
                              : "N/A"
                          }
                        />
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <DetailsItem
                          name="Address 2"
                          value={
                            user?.streetAddress?.address2 !== ""
                              ? user?.streetAddress?.address2
                              : "N/A"
                          }
                        />
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                        <DetailsItem
                          name="City"
                          value={
                            user?.streetAddress?.city !== ""
                              ? user?.streetAddress?.city
                              : "N/A"
                          }
                        />
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                        <DetailsItem
                          name="State/Province"
                          value={
                            user?.streetAddress?.state !== ""
                              ? user?.streetAddress?.state
                              : "N/A"
                          }
                        />
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                        <DetailsItem
                          name="Zip/Postal Code"
                          value={
                            user?.streetAddress?.postalCode !== ""
                              ? user?.streetAddress?.postalCode
                              : "N/A"
                          }
                        />
                      </div>
                      {user?.billingAddressSameAsStreeAddress && (
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                          <label
                            style={{
                              textAlign: "left",
                              marginBottom: "0px",
                              paddingLeft: "4px",
                            }}
                          >
                            Billing Address Same As Stree Address
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                  {!user?.billingAddressSameAsStreeAddress && (
                    <>
                      <hr />
                      <div className="flex-column">
                        <h4>Billing Address</h4>
                        <div className="row row-gap-1">
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <DetailsItem
                              name="Address 1"
                              value={
                                user?.billingAddress?.address1 !== ""
                                  ? user?.billingAddress?.address1
                                  : "N/A"
                              }
                            />
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <DetailsItem
                              name="Address 2"
                              value={
                                user?.billingAddress?.address2 !== ""
                                  ? user?.billingAddress?.address2
                                  : "N/A"
                              }
                            />
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                            <DetailsItem
                              name="City"
                              value={
                                user?.billingAddress?.city !== ""
                                  ? user?.billingAddress?.city
                                  : "N/A"
                              }
                            />
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                            <DetailsItem
                              name="State/Province"
                              value={
                                user?.billingAddress?.state !== ""
                                  ? user?.billingAddress?.state
                                  : "N/A"
                              }
                            />
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                            <DetailsItem
                              name="Zip/Postal Code"
                              value={
                                user?.billingAddress?.postalCode !== ""
                                  ? user?.billingAddress?.postalCode
                                  : "N/A"
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="orders">
                  <h4>Orders</h4>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
};
