import { useMutation } from "@tanstack/react-query";
import { ProfileInput, SignInResponse } from "../../../types";
import { updateCustomer } from "../../../api/auth/updateCustomer";
import { actions } from "../../../redux/auth/auth.slice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "../../../hooks/useRedux/useRedux";

export const useUpdateCustomer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return useMutation<SignInResponse, string, ProfileInput>(
    ["update-customer"],
    updateCustomer,
    {
      onSuccess: (user) => {
        const token = user.userSession.token;
        dispatch(
          actions.signIn({
            user,
            token,
          })
        );
        localStorage.setItem("ninoz-web-token", token);
        localStorage.setItem("ninoz-web-user", JSON.stringify(user));
        navigate("/profile");
      },
    }
  );
};
