import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App/App";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./scrollToTop";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthenticateProvider } from "./context/Authenticate/AuthenticateProvider";
import { ToastContainer, Zoom } from "react-toastify";

// Css
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";
import "../node_modules/magnific-popup/dist/magnific-popup.css";
import "../node_modules/react-perfect-scrollbar/dist/css/styles.css";
import "../node_modules/leaflet/dist/leaflet.css";
import "./assets/fonts/flaticon/flaticon.css";
import "./assets/fonts/font-awesome/css/all.min.css";
import "./assets/css/style.css";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";

const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: false,
    },
  },
});

const root = createRoot(document.getElementById("slices") as HTMLElement);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={reactQueryClient}>
      <ReduxProvider store={store}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <PayPalScriptProvider options={{ clientId: "test" }}>
            <AuthenticateProvider>
              <ScrollToTop />
              <App />
              <ToastContainer position="top-right" autoClose={1000} />
            </AuthenticateProvider>
          </PayPalScriptProvider>
        </BrowserRouter>
      </ReduxProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
