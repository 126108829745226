import classNames from "classnames";
import { forwardRef } from "react";
import { get } from "react-hook-form";

export interface CustomProps {
  onChange?: (event: { target: { name: string; value: string } }) => void;
  name: string;
  fieldId: string;
  errors: any;
  register: any;
  inputType: string;
  placeholder: string;
  disabled?: boolean;
  setValue: any;
  icon?: any;
  onClickIcon?: () => void;
}

export const InputField = forwardRef<any, CustomProps>((props, ref) => {
  const {
    errors,
    fieldId,
    inputType,
    name,
    placeholder,
    register,
    disabled = false,
    setValue,
    icon,
    onClickIcon,
    ...other
  } = props;

  const clearValue = (id: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    (document.getElementById(id) as HTMLInputElement).value = "";
    setValue(name, "");
  };

  return (
    <div className="input-group" style={{ minHeight: "82px" }}>
      <input
        {...other}
        ref={ref}
        disabled={disabled}
        type={inputType}
        name={name}
        placeholder={placeholder}
        className={classNames("form-control form-control-light", {
          "is-invalid": get(errors, fieldId),
        })}
        id={fieldId}
        {...register(fieldId, {
          required: "Required",
        })}
      />
      {icon && (
        <div className="input-group-prepend">
          <span
            className="input-group-text"
            id="basic-addon1"
            onClick={onClickIcon ? onClickIcon : () => {}}
          >
            {icon}
          </span>
        </div>
      )}
      {!disabled && (
        <div className="input-group-prepend">
          <span
            className="input-group-text"
            id="basic-addon1"
            onClick={() => clearValue(fieldId)}
            style={{
              cursor: "pointer",
              opacity: "0.5",
              fontWeight: "600",
              fontSize: "16px",
            }}
          >
            X
          </span>
        </div>
      )}
      {get(errors, fieldId) && (
        <div className="invalid-feedback" style={{ textAlign: "start" }}>
          {get(errors, fieldId).message}
        </div>
      )}
    </div>
  );
});
