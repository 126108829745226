import { createSlice } from "@reduxjs/toolkit"

const initialState = navigator.onLine

const internetConnectionSlice = createSlice({
	name: "internetConnection",
	initialState,
	reducers: {
		setInternetConnection: (state, action) => {
			return action.payload
		}
	}

})

export const actions = internetConnectionSlice.actions
export const reducer = internetConnectionSlice.reducer
