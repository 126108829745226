import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "../../../hooks/useRedux/useRedux";
import { cartSlice } from "../../../redux/cart/cart.slice";
import { Form } from "react-bootstrap";

export const Content = () => {
  const dispatch = useDispatch();
  const { list, pickupOrDelivery, totalTax, deliveryCharge, subtotal, total } =
    useSelector((e) => e.cart);

  const IncrementItem = (item) => {
    if (item.qty < 20) {
      dispatch(
        cartSlice.actions.updateQuantity({ ...item, qty: item.qty + 1 })
      );
    }
  };

  const DecreaseItem = (item) => {
    if (item.qty > 1) {
      dispatch(
        cartSlice.actions.updateQuantity({ ...item, qty: item.qty - 1 })
      );
    }
  };

  const removeItem = (item) => {
    dispatch(cartSlice.actions.removeItem(item));
  };

  const cloneItem = (item) => {
    dispatch(cartSlice.actions.cloneItem(item));
  };

  const handleChange = (e) => {
    e.persist();
    dispatch(cartSlice.actions.PickupOrDelivery(e.target.value));
  };

  return (
    <section className="section">
      <div className="container">
        {/* Cart Table Start */}
        <table className="ct-responsive-table">
          <thead>
            <tr>
              <th className="remove-item" />
              <th>Product</th>
              <th>Size</th>
              <th>Item Price</th>
              <th>Qunantity</th>
              <th>Subtotal</th>
            </tr>
          </thead>
          <tbody>
            {list.map((item, i) => (
              <tr key={i}>
                <td className="remove">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <button
                      type="button"
                      className="close-btn close-danger remove-from-cart"
                      onClick={() => removeItem(item)}
                    >
                      <span />
                      <span />
                    </button>
                    <button
                      type="button"
                      className="btn"
                      onClick={() => cloneItem(item)}
                    >
                      <i className="fa fa-copy" />
                    </button>
                  </div>
                </td>
                <td data-title="Product">
                  <div className="cart-product-wrapper">
                    <img
                      src={process.env.PUBLIC_URL + "/" + item.img}
                      alt={item.name}
                    />
                    <div className="cart-product-body">
                      <h6>
                        {item.name}
                        {item.cloneNumber !== null && item.cloneNumber > 0
                          ? " " + item.cloneNumber
                          : ""}
                        {item.size && <div></div>}
                      </h6>
                    </div>
                  </div>
                </td>
                <td data-title="Size">
                  {item.size && (
                    <div className="customize-size-wrapper">
                      <div className="customize-size-container" key={i}>
                        <div
                          key={i}
                          className="customize-size active"
                          style={{ cursor: "auto" }}
                        >
                          {item.size}"
                        </div>
                        <p
                          className="subtitle"
                          style={{ marginBottom: "20px" }}
                        >
                          {item.size === "10"
                            ? "Small"
                            : item.size === "12"
                            ? "Medium"
                            : item.size === "14"
                            ? "Large"
                            : "X-Large"}
                        </p>
                      </div>
                    </div>
                  )}
                </td>

                <td data-title="Price">
                  <strong>
                    ${new Intl.NumberFormat().format(item.price.toFixed(2))}
                  </strong>
                </td>
                <td className="quantity" data-title="Quantity">
                  <div className="qty">
                    <span
                      className="qty-subtract"
                      onClick={() => DecreaseItem(item)}
                    >
                      <i className="fa fa-minus" />
                    </span>
                    <input
                      type="text"
                      name="clicks"
                      value={item.qty}
                      readOnly
                    />
                    <span
                      className="qty-add"
                      onClick={() => IncrementItem(item)}
                    >
                      <i className="fa fa-plus" />
                    </span>
                  </div>
                </td>
                <td data-title="Total">
                  <strong>
                    $
                    {new Intl.NumberFormat().format(
                      (item.price * item.qty).toFixed(2)
                    )}
                  </strong>{" "}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* Cart Table End */}
        {/* Coupon Code Start */}
        <div className="row">
          <div className="col-lg-5">
            <div className="form-group mb-0">
              <div className="input-group mb-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Coupon Code"
                  aria-label="Coupon Code"
                />
                <div className="input-group-append">
                  <button className="btn-custom shadow-none" type="button">
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Coupon Code End */}
        {/* Cart form Start */}
        <div className="row ct-cart-form">
          <div className="offset-lg-6 col-lg-6">
            <h4>Cart Total</h4>
            <table>
              <tbody>
                <tr>
                  <th>Subtotal</th>
                  <td>
                    ${new Intl.NumberFormat().format(subtotal.toFixed(2))}
                  </td>
                </tr>
                <tr>
                  <th>Tax</th>
                  <td>${Number.parseFloat(totalTax).toFixed(2)}</td>
                </tr>
                <tr>
                  <th>Pickup/Delivery</th>
                  <td>
                    <Form.Group
                      controlId="pickup"
                      class="d-flex flex-row gap-5"
                    >
                      <Form.Check
                        value="pickup"
                        type="radio"
                        aria-label="radio 1"
                        label="Pickup"
                        class="custom-radio-input"
                        onChange={handleChange}
                        checked={pickupOrDelivery === "pickup"}
                      />
                      <div class="d-flex flex-row gap-2">
                        <Form.Check
                          value="delivery"
                          type="radio"
                          aria-label="radio 2"
                          label="Delivery"
                          class="custom-radio-input"
                          onChange={handleChange}
                          checked={pickupOrDelivery === "delivery"}
                        />
                        <span>${deliveryCharge}</span>
                      </div>
                    </Form.Group>
                  </td>
                </tr>
                <tr>
                  <th>Total</th>
                  <td>
                    <b>${total.toFixed(2)}</b>
                  </td>
                </tr>
              </tbody>
            </table>
            <Link to="/checkout">
              <button
                type="submit"
                className="btn-custom primary btn-block"
                disabled={list.length < 1}
              >
                Proceeed to Checkout
              </button>
            </Link>
          </div>
        </div>
        {/* Cart form End */}
      </div>
    </section>
  );
};
