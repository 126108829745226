import Axios, { InternalAxiosRequestConfig, AxiosResponse } from "axios";
import { ApiError } from "../types";

export const axios = Axios.create({
  baseURL: `${process.env.REACT_APP_SERVER ?? ''}`,
});

const axiosRequestInterceptor = (
  config: InternalAxiosRequestConfig<unknown>
): InternalAxiosRequestConfig<unknown> => {
  const accessToken = localStorage.getItem("ninoz-web-token");

  if (accessToken) {
    config.headers.set("security-token", accessToken);
  }

  return config;
};

const axiosSuccessResponseInterceptor = (response: AxiosResponse) => {
  return response;
};

const axiosFailureResponseInterceptor = (error: ApiError) => {
  const unauthorized = error.response?.status === 401;
  if (unauthorized) {
    localStorage.removeItem("ninoz-web-token");
    localStorage.removeItem("ninoz-web-user");

    const url = new URL(process.env.REACT_APP_SERVER ?? '', window.location.origin);
    window.location.replace(url.toString());
  }

  const CORS_ERROR_MESSAGE =
    "Unreachable error message due to Cross Origin issue";

  const GENERIC_ERROR_MESSAGE = "UNKNOWN ERROR";

  const crossOriginIssue = !error.response;
  if (crossOriginIssue) return Promise.reject(CORS_ERROR_MESSAGE);
  return Promise.reject(
    error.response?.data.localizedMessage ?? GENERIC_ERROR_MESSAGE
  );
};

axios.interceptors.request.use(axiosRequestInterceptor);
axios.interceptors.response.use(
  axiosSuccessResponseInterceptor,
  axiosFailureResponseInterceptor
);
