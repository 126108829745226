import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { reducer as authReducer } from "./auth/auth.slice";
import { reducer as internetConnectionReducer } from "./internetConnection/internetConnection.slice";
import { reducer as cartReducer } from "./cart/cart.slice";
import { reducer as storeConfigurationsReducer } from "./storeConfigurations/storeConfigurations.slice";


const reducer = combineReducers({
  auth: authReducer,
  internetConnection: internetConnectionReducer,
  cart: cartReducer,
  storeConfigurations: storeConfigurationsReducer,

});

export const store = configureStore({
  reducer,
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
