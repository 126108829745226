import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";

// Pages
import { Home } from "../components/pages/Home";
import { About } from "../components/pages/About";
import { Login } from "../components/pages/Login";
import { Register } from "../components/pages/Register";
import { Checkout } from "../components/pages/Checkout";
import { Cart } from "../components/pages/Cart";
import { Legal } from "../components/pages/Legal";
import { Error } from "../components/pages/Error";
import { Menuone } from "../components/pages/Menuone";
import { Menuitemone } from "../components/pages/Menuitemone";
import { Locations } from "../components/pages/Locations";
import { Contact } from "../components/pages/Contact";
import { VerifiedAccount } from "../components/pages/VerifiedAccount";
import { Profile } from "../components/pages/Profile";
import { EditProfile } from "../components/pages/EditProfile";
import { Confirmation } from "../components/pages/Confirmation";

// Preloader

function App() {
  const location = useLocation();
  return (
    <Routes location={location} key={location.pathname}>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route
        path="/register/verified-account/:email"
        element={<VerifiedAccount />}
      />
      <Route path="/profile" element={<Profile />} />
      <Route path="/edit-profile" element={<EditProfile />} />
      <Route path="/checkout" element={<Checkout />} />
      <Route path="/confirmation" element={<Confirmation />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/legal" element={<Legal />} />
      <Route path="/error" element={<Error />} />
      <Route path="/menu" element={<Menuone />} />
      <Route path="/menu-item/:id" element={<Menuitemone />} />
      <Route path="/locations" element={<Locations />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
  );
}

export default App;
