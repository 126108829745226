import { yupResolver } from "@hookform/resolvers/yup";
import { object } from "yup";
import * as Yup from "yup";
import {
  EMAIL_REGEX,
  LOWER_CHAR_REGEX,
  NUMBER_REGEX,
  SPECIAL_CHAR_REGEX,
  UPPER_CHAR_REGEX,
  USA_PHONE_REGEX,
} from "../constants/FORMATS";
import {
  EMAIL,
  GENERIC_REQ_MESSAGE,
  LOWER_CHAR,
  MIN_LEN_8,
  NUMBER,
  PHONE,
  SPECIAL_CHAR,
  UPPER_CHAR,
} from "../constants/VALIDATIONMESSAGE";

export const signUpResolver = yupResolver(
  object().shape({
    firstName: Yup.string().required(GENERIC_REQ_MESSAGE),
    lastName: Yup.string().required(GENERIC_REQ_MESSAGE),
    email: Yup.string()
      .required(GENERIC_REQ_MESSAGE)
      .matches(EMAIL_REGEX, EMAIL),
    phone: Yup.string()
      .required(GENERIC_REQ_MESSAGE)
      .matches(USA_PHONE_REGEX, PHONE),
    password: Yup.string()
      .required(GENERIC_REQ_MESSAGE)
      .min(8, MIN_LEN_8)
      .matches(LOWER_CHAR_REGEX, LOWER_CHAR)
      .matches(UPPER_CHAR_REGEX, UPPER_CHAR)
      .matches(SPECIAL_CHAR_REGEX, SPECIAL_CHAR)
      .matches(NUMBER_REGEX, NUMBER),
    confirmPassword: Yup.string()
      .required(GENERIC_REQ_MESSAGE)
      .oneOf([Yup.ref("password"), ""], "Passwords must match"),
  })
);
