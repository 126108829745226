import { useNavigate, useParams } from "react-router-dom";

export const Content = () => {
  const { email } = useParams();
  const navigate = useNavigate();

  const handleContinue = () => {
    navigate('/login')
  }

  return (
    <div className="section">
      <div className="imgs-wrapper">
        <img
          src={process.env.PUBLIC_URL + "/assets/img/veg/11.png"}
          alt="veg"
          className="d-none d-lg-block"
        />
        <img
          src={process.env.PUBLIC_URL + "/assets/img/prods/supreme-pizza.png"}
          alt="veg"
          className="d-none d-lg-block"
        />
      </div>
      <div className="container">
        <div className="auth-wrapper">
          <div
            className="auth-description bg-cover bg-center dark-overlay dark-overlay-2"
            style={{
              backgroundImage:
                "url(" + process.env.PUBLIC_URL + "/assets/img/auth.jpeg)",
            }}
          >
            <div className="auth-description-inner">
              <i className="flaticon-chili" />
              <h2>Ninoz Pizza & Pasta</h2>
              <p>
                500 N HWY 377 Suite 150 <br />
                Roanoke TX 7622
              </p>
            </div>
          </div>

          <div className="auth-form">
            <h2>Sign Up</h2>
            <img
              src={process.env.PUBLIC_URL + "/assets/img/verified-icon.png"}
              alt="verified-icon"
              width="50px"
              height="50px"
            />
            <h4>Thank you for signing up,</h4>
            <p>Your account {email} has been created.</p>
            <button className="btn-custom primary" onClick={handleContinue}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
