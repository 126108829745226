import { useQuery } from "@tanstack/react-query";
import { SignInResponse } from "../../types";
import { verifyUserSession } from "../../api/auth/verifyUserSession";
import { useDispatch } from "../../hooks/useRedux/useRedux";
import { actions } from "../../redux/auth/auth.slice";
import { useAuth } from "../../hooks/useAuth/useAuth";
import { useLocation, useNavigate } from "react-router-dom";

const reValidateMilliseconds = 360000;

export const useVerifyUserSession = () => {
  const dispatch = useDispatch();
  const { userAvailability } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  return useQuery<SignInResponse, string>(
    ["verify-user-session"],
    verifyUserSession,
    {
      onSuccess: (user) => {
        const token = user.userSession.token;
        dispatch(
          actions.signIn({
            user,
            token,
          })
        );
        localStorage.setItem("ninoz-web-token", token);
        localStorage.setItem("ninoz-web-user", JSON.stringify(user));
      },
      refetchInterval: reValidateMilliseconds,
      staleTime: reValidateMilliseconds,
      retry: false,
      enabled: userAvailability,
      useErrorBoundary: false,
      onError: () => {
        if (
          location.pathname === "/profile" ||
          location.pathname === "/edit-profile"
        ) {
          navigate("/login");
        } else {
          navigate("/");
        }
        dispatch(actions.signOut());
      },
    }
  );
};
