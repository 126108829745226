import { User } from "../../types/auth";
import { useSelector } from "../useRedux/useRedux";

export const useAuth = () => {
  const auth = useSelector((s) => s.auth);

  const user: User | null = auth.user;
  const token: string | null = auth.token;

  let userAvailability: boolean = true;

  if (!user) {
    userAvailability = false;
  }

  return {
    userAvailability,
    user,
    token,
  };
};
