import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Headerinner";
import Breadcrumbs from "../layouts/Breadcrumb";
import { Footer } from "../layouts/Footer";
import { Content } from "../sections/menu-item-v1/Content";
import { useParams } from "react-router-dom";
import { useSelector } from "../../hooks/useRedux/useRedux";

const pagelocation = "Menu Item";

export const Menuitemone = () => {
  const { id } = useParams();
  const list = useSelector((state) => state.cart.list);
  return (
    <Fragment>
      <MetaTags>
        <title>Ninoz Pizza | {pagelocation}</title>
        <meta name="description" content="#" />
      </MetaTags>
      <Header cartListLength={list.length} />
      <Breadcrumbs
        breadcrumb={{
          pagename: pagelocation,
          subpage: {
            link: "/menu",
            name: "Menu",
          },
        }}
      />
      <Content productId={id} />
      <Footer
        footer={{
          style: "ct-footer footer-dark",
          logo: "assets/img/logo-light.png",
        }}
      />
    </Fragment>
  );
};
