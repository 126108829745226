import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Headerinner";
import Breadcrumbs from "../layouts/Breadcrumb";
import {Footer} from "../layouts/Footer";
import {Content} from "../sections/cart/Content";

const pagelocation = "Cart";
export const Cart = () => {
  return (
    <Fragment>
      <MetaTags>
        <title>Ninoz Pizza | {pagelocation}</title>
        <meta name="description" content="#" />
      </MetaTags>
      <Header />
      <Breadcrumbs breadcrumb={{ pagename: pagelocation }} />
      <Content />
      <Footer
        footer={{
          style: "ct-footer footer-dark",
          logo: "assets/img/logo-light.png",
        }}
      />
    </Fragment>
  );
};
