import { useCallback, useState } from "react";
import Slider from "react-slick";
import home from "../../../data/banner.json";
import { Link } from "react-router-dom";

const settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dots: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
      },
    },
  ],
};
const settingsthumb = {
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  dots: false,
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        autoplay: true,
      },
    },
  ],
};

export const Banner = () => {
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  const [size, setSize] = useState("12");

  const next = useCallback(() => {
    slider2.slickNext();
  }, []);

  const previous = useCallback(() => {
    slider2.slickPrev();
  }, []);

  return (
    <div className="banner banner-1 bg-cover">
      <div className="imgs-wrapper">
        <img
          src={process.env.PUBLIC_URL + "/assets/img/veg/11.png"}
          alt="veg"
          className="d-none d-lg-block"
        />
      </div>
      <Slider
        className="banner-slider"
        {...settings}
        asNavFor={slider2}
        ref={(slider) => setSlider1(slider)}
      >
        {home.home.map((item, i) => (
          <div key={i} className="banner-item">
            <div className="banner-inner">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-xl-6 col-lg-6">
                    <h1 className="title">{item.name}</h1>
                    <h4>{item.subtitle}</h4>
                    <p className="subtitle">{item.shortdesc}</p>
                    <div className="customize-size-wrapper">
                      <h5>Size:</h5>
                      {item.sizes.map((item, i) => (
                        <div className="customize-size-container">
                          <div
                            key={i}
                            className={
                              size === item.size
                                ? "customize-size active"
                                : "customize-size"
                            }
                            onClick={() => {
                              setSize(item.size);
                            }}
                          >
                            {item.size}"
                          </div>
                          {size === item.size && (
                            <p
                              className="subtitle"
                              style={{ marginBottom: "20px" }}
                            >
                              {size === "10"
                                ? "Small"
                                : size === "12"
                                ? "Medium"
                                : size === "14"
                                ? "Large"
                                : "X-Large"}
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                    <div className="banner-controls">
                      <Link
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="btn-custom primary"
                        to={`/menu-item/${item.id}`}
                      >
                        Order online
                        <i className="fas fa-shopping-cart" />
                      </Link>
                      <h4>
                        $
                        {new Intl.NumberFormat().format(
                          item.sizes
                            .find((item) => item.size === size)
                            .price.toFixed(2)
                        )}
                      </h4>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6">
                    <img
                      src={process.env.PUBLIC_URL + "/" + item.img}
                      alt={item.name}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <div className="banner-slider-nav">
        <div className="container">
          {/* Arrow */}
          <i
            className="slider-prev fas fa-arrow-left slick-arrow"
            onClick={previous}
          />
          <Slider
            className="banner-slider-nav-inner"
            {...settingsthumb}
            asNavFor={slider1}
            ref={(slider) => setSlider2(slider)}
          >
            {home.home.map((item, i) => (
              <div key={i} className="banner-nav-item">
                <div className="banner-nav-item-inner">
                  {item.offer === true ? (
                    <div className="sale">
                      <div className="sale-inner"> Offer </div>{" "}
                    </div>
                  ) : (
                    ""
                  )}
                  <img
                    src={process.env.PUBLIC_URL + "/" + item.img}
                    alt={item.name}
                  />
                  <div className="banner-nav-item-body">
                    <h5>{item.name}</h5>
                    {item.discount > 0 || item.discount !== "" ? (
                      <span>
                        $
                        {new Intl.NumberFormat().format(
                          item.sizes
                            .find((item) => item.size === size)
                            .price.toFixed(2)
                        )}
                      </span>
                    ) : (
                      ""
                    )}
                    <span>
                      $
                      {new Intl.NumberFormat().format(
                        item.sizes
                          .find((item) => item.size === size)
                          .price.toFixed(2)
                      )}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
          {/* Arrow */}
          <i
            className="slider-next fas fa-arrow-right slick-arrow"
            onClick={next}
          />
        </div>
      </div>
    </div>
  );
};
