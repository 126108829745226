import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import Header from "../layouts/Headerthree";
import { Footer } from "../layouts/Footer";
import { useSelector } from "../../hooks/useRedux/useRedux";
import { Content } from "../sections/edit-profile/Content";

const pagelocation = "Profile";

export const EditProfile = () => {
  const list = useSelector((state) => state.cart.list);

  return (
    <Fragment>
      <MetaTags>
        <title>Ninoz Pizza | {pagelocation}</title>
        <meta name="description" content="#" />
      </MetaTags>
      <Header cartListLength={list.length} />
      <Content />
      <Footer
        footer={{
          style: "ct-footer footer-dark",
          logo: "assets/img/logo-light.png",
        }}
      />
    </Fragment>
  );
};
