import { axios } from "../../libs/axios";

export interface ListItem {
  id: string;
  name: string;
  description: string;
  value: string;
  parameter: string;
}

export const getListData = async (name: string) => {
  const response = await axios.get<ListItem[]>(`/listdata?name=${name}`);
  return response.data;
};
