import { yupResolver } from "@hookform/resolvers/yup";
import { object } from "yup";
import * as Yup from "yup";
import { EMAIL_REGEX, USA_PHONE_REGEX } from "../constants/FORMATS";
import {
  CARD_NUMBER,
  EMAIL,
  GENERIC_REQ_MESSAGE,
  INVALID_DATE,
  MAX_LEN_3_DIGITS,
  MAX_LEN_4_DIGITS,
  MIN_LEN_3_DIGITS,
  MIN_LEN_4_DIGITS,
  PHONE,
} from "../constants/VALIDATIONMESSAGE";
import moment from "moment";

export const paymentResolver = (
  pickupOrDelivery: "pickup" | "delivery",
  creditCard: boolean
) => {
  return yupResolver(
    object().shape({
      id: Yup.string().notRequired(),
      firstName: Yup.string().required(GENERIC_REQ_MESSAGE),
      lastName: Yup.string().required(GENERIC_REQ_MESSAGE),
      email: Yup.string()
        .required(GENERIC_REQ_MESSAGE)
        .matches(EMAIL_REGEX, EMAIL),
      phone: Yup.string()
        .required(GENERIC_REQ_MESSAGE)
        .matches(USA_PHONE_REGEX, PHONE),
      companyName: Yup.string().notRequired(),
      orderNotes: Yup.string().notRequired(),
      billingAddressSameAsStreeAddress: Yup.boolean().notRequired(),
      streetAddress: Yup.object({
        address1:
          pickupOrDelivery === "delivery"
            ? Yup.string().required(GENERIC_REQ_MESSAGE)
            : Yup.string().notRequired(),
        address2: Yup.string().notRequired(),
        city:
          pickupOrDelivery === "delivery"
            ? Yup.string().required(GENERIC_REQ_MESSAGE)
            : Yup.string().notRequired(),
        postalCode:
          pickupOrDelivery === "delivery"
            ? Yup.string().required(GENERIC_REQ_MESSAGE)
            : Yup.string().notRequired(),
        state:
          pickupOrDelivery === "delivery"
            ? Yup.string().required(GENERIC_REQ_MESSAGE)
            : Yup.string().notRequired(),
      }),
      billingAddress: Yup.object({
        address1: Yup.string().notRequired(),
        address2: Yup.string().notRequired(),
        city: Yup.string().notRequired(),
        postalCode: Yup.string().notRequired(),
        state: Yup.string().notRequired(),
      }),
      cardNumber: creditCard
        ? Yup.string()
            .required(GENERIC_REQ_MESSAGE)
            .test("cardNumber", CARD_NUMBER, function (value) {
              return (
                value.startsWith("30") ||
                value.startsWith("38") ||
                value.startsWith("2") ||
                value.startsWith("3") ||
                value.startsWith("4") ||
                value.startsWith("5") ||
                value.startsWith("6")
              );
            })
        : Yup.string().notRequired(),
      fullName: creditCard
        ? Yup.string().required(GENERIC_REQ_MESSAGE)
        : Yup.string().notRequired(),
      expiryDate: creditCard
        ? Yup.string()
            .required(GENERIC_REQ_MESSAGE)
            .test("expiryDate", INVALID_DATE, (value) => {
              return !moment(value, "MM/YY").add(1, "month").isSameOrBefore();
            })
        : Yup.string().notRequired(),
      cvv: creditCard
        ? Yup.string()
            .required(GENERIC_REQ_MESSAGE)
            .when("cardNumber", ([cardNumber], field) => {
              if (cardNumber.startsWith("3")) {
                return field.min(4, MIN_LEN_4_DIGITS).max(4, MAX_LEN_4_DIGITS);
              } else {
                return field.min(3, MIN_LEN_3_DIGITS).max(3, MAX_LEN_3_DIGITS);
              }
            })
        : Yup.string().notRequired(),
    })
  );
};
