import React, { Component } from 'react';

class Clear extends Component {
    render() {
        return (
            <div className="section">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 mb-lg-30 ct-single-img-wrapper">
                            <div className="ct-dots" />
                        </div>
                        <div className="col-lg-6">
                            <div className="section-title-wrap mr-lg-30">
                                <p className="subtitle"></p>
                                <p className="subtitle"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Clear;