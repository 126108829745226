import React from "react";
import { InputField } from "../../layouts/InputField";
import { useForm } from "react-hook-form";
import { SignInCustomerInput } from "../../../types";
import { useSignIn } from "./useSignIn";

export const SignInCustomer = () => {
  const signIn = useSignIn();

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm<SignInCustomerInput>();

  const onSubmit = handleSubmit(async (data) => {
    signIn.mutate(data);
  });

  return (
    <div className="ct-notice-content d-block">
      <form method="post" onSubmit={onSubmit}>
        <div className="row">
          <div className="col-xl-6 form-group">
            <InputField
              setValue={setValue}
              fieldId={"email"}
              errors={errors}
              register={register}
              inputType={"text"}
              placeholder="Email"
              name="Email"
            />
          </div>
          <div className="col-xl-6 form-group">
            <InputField
              setValue={setValue}
              fieldId={"password"}
              errors={errors}
              register={register}
              inputType={"password"}
              placeholder="Password"
              name="Password"
            />
          </div>
          {signIn.error && (
            <div className="alert alert-danger" role="alert">
              {signIn.error}
            </div>
          )}
          <div className="col-12">
            <button
              type="submit"
              className="btn-custom primary"
              onClick={onSubmit}
            >
              {signIn.isLoading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Login"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
