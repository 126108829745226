import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { InputField } from "../../layouts/InputField";
import { useSignUpCustomer } from "./useSignUpCustomer";
import { InputFieldWithMask } from "../../layouts/InputFieldWithMask";
import { signUpResolver } from "../../../validation/signUpResolver";
import { useState } from "react";

export const Content = () => {
  const signUpCustomer = useSignUpCustomer();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      firstName: "",
      confirmPassword: "",
      email: "",
      lastName: "",
      password: "",
      phone: "",
    },
    resolver: signUpResolver,
  });

  const onSubmit = handleSubmit(async (data) => {
    signUpCustomer.mutate(data, {
      onSuccess: () => {
        navigate(`/register/verified-account/${data.email}`);
      },
    });
  });

  function containsSpecialChars(str) {
    const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>\\/?~]/;
    return specialChars.test(str);
  }

  function containsNumbers(str) {
    const numericalValue = /[0-9]/;
    return numericalValue.test(str);
  }

  function containsUppercase(str) {
    const capitalLetter = /[A-Z]/;
    return capitalLetter.test(str);
  }

  return (
    <div className="section">
      <div className="imgs-wrapper">
        <img
          src={process.env.PUBLIC_URL + "/assets/img/veg/11.png"}
          alt="veg"
          className="d-none d-lg-block"
        />
        <img
          src={process.env.PUBLIC_URL + "/assets/img/prods/supreme-pizza.png"}
          alt="veg"
          className="d-none d-lg-block"
        />
      </div>
      <div className="container">
        <div className="auth-wrapper">
          <div
            className="auth-description bg-cover bg-center dark-overlay dark-overlay-2"
            style={{
              backgroundImage:
                "url(" + process.env.PUBLIC_URL + "/assets/img/auth.jpeg)",
            }}
          >
            <div className="auth-description-inner">
              <i className="flaticon-chili" />
              <h2>Ninoz Pizza & Pasta</h2>
              <p>
                500 N HWY 377 Suite 150 <br />
                Roanoke TX 7622
              </p>
            </div>
          </div>

          <div className="auth-form">
            <h2>Sign Up</h2>

            <form method="post" onSubmit={onSubmit}>
              <div className="row">
                <label style={{ textAlign: "left" }}>Name</label>
              </div>
              <div className="row">
                <div className="col-xl-6 form-group">
                  <InputField
                    setValue={setValue}
                    fieldId="firstName"
                    errors={errors}
                    register={register}
                    inputType="text"
                    placeholder="First Name"
                    name="firstName"
                  />
                </div>
                <div className="col-xl-6 form-group">
                  <InputField
                    setValue={setValue}
                    fieldId="lastName"
                    errors={errors}
                    register={register}
                    inputType="text"
                    placeholder="Last Name"
                    name="lastName"
                  />
                </div>
              </div>
              <div className="row">
                <label style={{ textAlign: "left" }}>Email</label>
              </div>
              <div className="form-group">
                <InputField
                  setValue={setValue}
                  fieldId="email"
                  errors={errors}
                  register={register}
                  inputType="text"
                  placeholder="Email"
                  name="email"
                />
              </div>
              <div className="row">
                <label style={{ textAlign: "left" }}>Phone</label>
              </div>
              <div className="form-group">
                <InputFieldWithMask
                  setValue={setValue}
                  fieldId="phone"
                  errors={errors}
                  register={register}
                  inputType="phone"
                  placeholder="Phone"
                  name="phone"
                  mask="(000) 000-0000"
                />
              </div>
              <div className="row">
                <label style={{ textAlign: "left" }}>Password</label>
              </div>
              <div className="form-group">
                <InputField
                  setValue={setValue}
                  fieldId="password"
                  errors={errors}
                  register={register}
                  inputType={showPassword ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  onClickIcon={() => setShowPassword(!showPassword)}
                  icon={
                    !showPassword ? (
                      <i className="fa fa-eye"></i>
                    ) : (
                      <i className="fa fa-eye-slash"></i>
                    )
                  }
                />
              </div>
              <div className="form-group">
                <InputField
                  setValue={setValue}
                  fieldId="confirmPassword"
                  errors={errors}
                  register={register}
                  inputType={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  name="confirmPassword"
                  onClickIcon={() =>
                    setShowConfirmPassword(!showConfirmPassword)
                  }
                  icon={
                    !showConfirmPassword ? (
                      <i className="fa fa-eye"></i>
                    ) : (
                      <i className="fa fa-eye-slash"></i>
                    )
                  }
                />
              </div>
              <div
                className="d-flex flex-column"
                style={{
                  width: "100%",
                }}
              >
                <div className="d-flex flex-row gap-1 justify-content-between">
                  <label>At least 1 capital letter</label>
                  {(containsUppercase(watch("password") || "") ||
                    containsUppercase(watch("confirmPassword") || "")) && (
                    <i className="fa fa-check" />
                  )}
                </div>
                <div className="d-flex flex-row gap-1 justify-content-between">
                  <label>At least 1 numerical value</label>
                  {(containsNumbers(watch("password") || "") ||
                    containsNumbers(watch("confirmPassword") || "")) && (
                    <i className="fa fa-check" />
                  )}
                </div>
                <div className="d-flex flex-row gap-1 justify-content-between">
                  <label>At least 1 special character</label>
                  {(containsSpecialChars(watch("password") || "") ||
                    containsSpecialChars(watch("confirmPassword") || "")) && (
                    <i className="fa fa-check" />
                  )}
                </div>
              </div>
              <div style={{ minHeight: "62px" }}>
                {signUpCustomer.error && (
                  <div className="alert alert-danger" role="alert">
                    {signUpCustomer.error}
                  </div>
                )}
              </div>
              <button type="submit" className="btn-custom primary">
                {signUpCustomer.isLoading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "Sign Up"
                )}
              </button>
              {/* <div className="auth-seperator">
                  <span>OR</span>
                </div>
                <div className="social-login">
                  <button type="button" className="ct-social-login facebook">
                    <i className="fab fa-facebook-f" /> Continue with Facebook{" "}
                  </button>
                  <button type="button" className="ct-social-login google">
                    <i className="fab fa-google" /> Continue with Google
                  </button>
                </div>
                            */}
              <p className="pt-1">
                Already have an account? <Link to="/login">Login</Link>{" "}
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
