import { useMutation } from "@tanstack/react-query";
import { SignInCustomerInput, SignInResponse } from "../../../types";
import { signInCustomer } from "../../../api/auth/signInCustomer";
import { useDispatch } from "../../../hooks/useRedux/useRedux";
import { actions } from "../../../redux/auth/auth.slice";

export const useSignIn = () => {
  const dispatch = useDispatch();

  return useMutation<SignInResponse, string, SignInCustomerInput>(
    ["sign-in"],
    signInCustomer,
    {
      onSuccess: (user) => {
        const token = user.userSession.token;
        dispatch(
          actions.signIn({
            user,
            token,
          })
        );
        localStorage.setItem("ninoz-web-user", JSON.stringify(user));
        localStorage.setItem("ninoz-web-token", token);
      },
    }
  );
};
