import { useQuery } from "@tanstack/react-query";
import { ListItem, getListData } from "../../../api/Listdate/getListData";

export const useGetList = (name: string) => {
  return useQuery<ListItem[], string>(
    ["get-list-items", name],
    async ({ queryKey }) => getListData(queryKey[1] as string),
    {
      onSuccess: (data) => {
        localStorage.setItem(
          "ninoz-web-store-configurations",
          JSON.stringify(data)
        );
      },
    }
  );
};
