import { createSlice } from "@reduxjs/toolkit"
import { ListItem } from "../../api/Listdate/getListData";

var configurationsList = localStorage.getItem("ninoz-web-store-configurations") || null;
const initialState: ListItem[] = configurationsList ? JSON.parse(configurationsList) : []

const storeConfigurations = createSlice({
	name: "storeConfigurations",
	initialState,
	reducers: {
		setInternetConnection: (state, action) => {
            state = action.payload;
		}
	}

})

export const actions = storeConfigurations.actions
export const reducer = storeConfigurations.reducer
