import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { ListItem } from "../../api/Listdate/getListData";

export type cartItem = {
  id: number;
  img: string;
  name: string;
  price: number;
  size: string;
  shortdesc: string;
  qty: number;
  cloneNumber: number;
  availableSizesWithPrice: any[];
};

export type updateCartItem = {
  oldItem: cartItem;
  newItem: cartItem;
};

export type Type = {
  list: cartItem[];
  subtotal: number;
  total: number;
  tax: number;
  totalTax: number;
  open: boolean;
  pickupOrDelivery: "pickup" | "delivery";
  deliveryCharge: number;
};

const subtotalStorage = sessionStorage.getItem("ninoz-web-subtotal") || null;
const listStorage = sessionStorage.getItem("ninoz-web-list") || null;
const pickupOrDeliveryStorage =
  sessionStorage.getItem("ninoz-web-pickupOrDelivery") || null;
const configurationsStoreStorage =
  localStorage.getItem("ninoz-web-store-configurations") || null;

const list = listStorage ? JSON.parse(listStorage) : [];
const pickupOrDelivery = pickupOrDeliveryStorage
  ? JSON.parse(pickupOrDeliveryStorage)
  : "pickup";
const configurationsList: ListItem[] = configurationsStoreStorage
  ? JSON.parse(configurationsStoreStorage)
  : [];
const tax =
  Number(configurationsList?.find((e) => e.parameter === "Sales TAX")?.value) ??
  8.25;
const deliveryCharge =
  Number(
    configurationsList?.find((e) => e.parameter === "Delivery Charge")?.value
  ) ?? 4.0;

const subtotal =
  (subtotalStorage ? JSON.parse(subtotalStorage) : 0) +
  (pickupOrDelivery === "delivery" && deliveryCharge);
const totalTax = (subtotal * tax) / 100;

const initialState: Type = {
  open: false,
  list: list,
  pickupOrDelivery: pickupOrDelivery,
  subtotal: subtotal,
  tax: tax,
  totalTax: subtotal * (tax / 100),
  deliveryCharge: deliveryCharge,
  total: subtotal + totalTax,
};

const addToCart: CaseReducer<Type, PayloadAction<cartItem>> = (
  state,
  action
) => {
  const check = state.list.findIndex((item) => item.id === action.payload.id);

  if (check !== -1) {
    state.list[check].qty += action.payload.qty;
  } else {
    state.list.push({ ...action.payload });
  }

  state.subtotal = state.list.reduce(
    (sum, item) => sum + +item?.price * item?.qty,
    0
  );
  state.subtotal +=
    state.pickupOrDelivery === "delivery" ? state.deliveryCharge : 0;
  state.totalTax = (state.subtotal * tax) / 100;
  state.total = state.subtotal + state.totalTax;

  sessionStorage.setItem("ninoz-web-subtotal", JSON.stringify(state.subtotal));
  sessionStorage.setItem("ninoz-web-list", JSON.stringify(state.list));
  sessionStorage.setItem("ninoz-web-total", JSON.stringify(state.total));
};

const resetCart: CaseReducer<Type> = (state) => {
  state.list = [];
  state.subtotal = 0;
  state.total = 0;

  sessionStorage.setItem("ninoz-web-subtotal", JSON.stringify(state.subtotal));
  sessionStorage.setItem("ninoz-web-list", JSON.stringify(state.list));
  sessionStorage.setItem("ninoz-web-total", JSON.stringify(state.total));
};

const updateQuantity: CaseReducer<Type, PayloadAction<cartItem>> = (
  state,
  action
) => {
  state.list = state.list.map((item) => {
    if (item.id === action.payload.id) {
      item.qty = action.payload.qty;
    }
    return item;
  });

  state.subtotal = state.list.reduce(
    (sum, item) => sum + +item?.price * item?.qty,
    0
  );
  state.subtotal +=
    state.pickupOrDelivery === "delivery" ? state.deliveryCharge : 0;
  state.totalTax = (state.subtotal * tax) / 100;
  state.total = state.subtotal + state.totalTax;

  sessionStorage.setItem("ninoz-web-subtotal", JSON.stringify(state.subtotal));
  sessionStorage.setItem("ninoz-web-list", JSON.stringify(state.list));
  sessionStorage.setItem("ninoz-web-total", JSON.stringify(state.total));
};

const removeItem: CaseReducer<Type, PayloadAction<cartItem>> = (
  state,
  action
) => {
  state.list = state.list.filter(
    (item) =>
      action.payload.id !== item.id ||
      item.cloneNumber !== action.payload.cloneNumber
  );

  state.subtotal = state.list.reduce(
    (sum, item) => sum + +item?.price * item?.qty,
    0
  );
  state.subtotal +=
    state.pickupOrDelivery === "delivery" ? state.deliveryCharge : 0;
  state.totalTax = (state.subtotal * tax) / 100;
  state.total = state.subtotal + state.totalTax;

  sessionStorage.setItem("ninoz-web-subtotal", JSON.stringify(state.subtotal));
  sessionStorage.setItem("ninoz-web-list", JSON.stringify(state.list));
  sessionStorage.setItem("ninoz-web-total", JSON.stringify(state.total));
};

const updateSize: CaseReducer<Type, PayloadAction<updateCartItem>> = (
  state,
  action
) => {
  const checkNewItem = state.list.findIndex(
    (item) => item.id === action.payload.newItem.id
  );

  if (action.payload.newItem.id !== action.payload.oldItem.id) {
    if (checkNewItem !== -1) {
      state.list[checkNewItem].qty += action.payload.oldItem.qty;
    } else {
      state.list.push({ ...action.payload.newItem });
    }

    state.subtotal = state.list.reduce(
      (sum, item) => sum + +item?.price * item?.qty,
      0
    );
    state.subtotal +=
      state.pickupOrDelivery === "delivery" ? state.deliveryCharge : 0;
    state.totalTax = (state.subtotal * tax) / 100;
    state.total = state.subtotal + state.totalTax;
  }
};

const cloneItem: CaseReducer<Type, PayloadAction<cartItem>> = (
  state,
  action
) => {
  const checkItem = state.list.findLastIndex(
    (item) => item.id === action.payload.id
  );

  state.list.push({
    ...action.payload,
    cloneNumber:
      state.list[checkItem].cloneNumber &&
      state.list[checkItem].cloneNumber !== null
        ? state.list[checkItem].cloneNumber + 1
        : 1,
  });

  state.subtotal = state.list.reduce(
    (sum, item) => sum + +item?.price * item?.qty,
    0
  );
  state.subtotal +=
    state.pickupOrDelivery === "delivery" ? state.deliveryCharge : 0;
  state.totalTax = (state.subtotal * tax) / 100;
  state.total = state.subtotal + state.totalTax;

  sessionStorage.setItem("ninoz-web-subtotal", JSON.stringify(state.subtotal));
  sessionStorage.setItem("ninoz-web-list", JSON.stringify(state.list));
  sessionStorage.setItem("ninoz-web-total", JSON.stringify(state.total));
};

const openCart: CaseReducer<Type> = (state) => {
  state.open = true;
};

const closeCart: CaseReducer<Type> = (state) => {
  state.open = false;
};

const PickupOrDelivery: CaseReducer<
  Type,
  PayloadAction<"pickup" | "delivery">
> = (state, action) => {
  state.pickupOrDelivery = action.payload;

  state.subtotal = state.list.reduce(
    (sum, item) => sum + +item?.price * item?.qty,
    0
  );
  state.subtotal +=
    state.pickupOrDelivery === "delivery" ? state.deliveryCharge : 0;
  state.totalTax = (state.subtotal * tax) / 100;
  state.total = state.subtotal + state.totalTax;

  sessionStorage.setItem(
    "ninoz-web-pickupOrDelivery",
    JSON.stringify(state.pickupOrDelivery)
  );
  sessionStorage.setItem("ninoz-web-total", JSON.stringify(state.total));
};

export const cartSlice = createSlice({
  name: "Cart",
  initialState,
  reducers: {
    addToCart,
    removeItem,
    openCart,
    closeCart,
    updateQuantity,
    updateSize,
    cloneItem,
    PickupOrDelivery,
    resetCart,
  },
});

export const reducer = cartSlice.reducer;
