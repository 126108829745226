import { AX_CARD_MASK, GENERIC_CARD_MASK } from "../../../constants/FORMATS";

export const handleCardNumber = (number) => {
  if (number.startsWith("30")) {
    return {
      name: "JCB",
      identerifier: "JCB",
      icon: "assets/img/paymentMethods/jcb.svg",
      mask: GENERIC_CARD_MASK,
      CVVLength: "3",
    };
  } else if (number.startsWith("38")) {
    return {
      name: "Diners Club/ Carte Blanche",
      identerifier: "diners-club",
      icon: "assets/img/paymentMethods/diners-club.svg",
      mask: GENERIC_CARD_MASK,
      CVVLength: "3",
    };
  } else if (number.startsWith("3")) {
    return {
      name: "American express",
      identerifier: "AX",
      icon: "assets/img/paymentMethods/ax.svg",
      mask: AX_CARD_MASK,
      CVVLength: "4",
    };
  } else if (number.startsWith("4")) {
    return {
      name: "Visa",
      identerifier: "VI",
      icon: "assets/img/paymentMethods/vi.svg",
      mask: GENERIC_CARD_MASK,
      CVVLength: "3",
    };
  } else if (number.startsWith("5") || number.startsWith("2")) {
    return {
      name: "Mastercard",
      identerifier: "MC",
      icon: "assets/img/paymentMethods/mc.svg",
      mask: GENERIC_CARD_MASK,
      CVVLength: "3",
    };
  } else if (number.startsWith("6")) {
    return {
      name: "Discover",
      identerifier: "DI",
      icon: "assets/img/paymentMethods/discover.svg",
      mask: GENERIC_CARD_MASK,
      CVVLength: "3",
    };
  } else {
    return {
      name: "Credit Card",
      identerifier: "GENERIC",
      icon: "assets/img/paymentMethods/credit-card.svg",
      mask: "000000000000000000000000000",
      CVVLength: "4",
    };
  }
};
