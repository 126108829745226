import React from "react";
import { Link } from "react-router-dom";
import { useApp } from "../../hooks/useApp/useApp";
import { useAuth } from "../../hooks/useAuth/useAuth";

export const Footer = (props) => {
  const { userAvailability } = useAuth();
  const { build } = useApp();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className={props.footer.style}>
      {/* Top Footer */}
      <div className="container">
        <div className="footer-top">
          <div className="footer-logo">
            <img
              src={process.env.PUBLIC_URL + "/assets/img/logo-removebg.png"}
              alt="logo"
            />
          </div>
        </div>
      </div>
      {/* Middle Footer */}
      <div className="footer-middle">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 footer-widget">
              <h5 className="widget-title">Information</h5>
              <ul>
                <li>
                  {" "}
                  <Link to="/">Home</Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link to="/about">About Us</Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link to="/menu">Menu</Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link to="/contact">Contact Us</Link>{" "}
                </li>
              </ul>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 footer-widget">
              <h5 className="widget-title">Top Items</h5>
              <ul>
                <li>
                  {" "}
                  <Link to="/menu-item/5">Margherita Pizza</Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link to="/menu-item/1">Supreme Pizza</Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link to="/menu-item/12">BBQ Chicken Pizza</Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link to="/menu-item/6">Chicken Alfredo Pizza</Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link to="/menu-item/3">Meat Lover Pizza</Link>{" "}
                </li>
              </ul>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 footer-widget">
              <h5 className="widget-title">Others</h5>
              <ul>
                <li>
                  {" "}
                  <Link to="/locations">Locations</Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link to="/cart">Cart</Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link to="/legal">Legal</Link>{" "}
                </li>
              </ul>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 footer-widget">
              <h5 className="widget-title">Social Media</h5>
              <ul className="social-media">
                <li>
                  {" "}
                  <Link to="#" className="facebook">
                    {" "}
                    <i className="fab fa-facebook-f" />{" "}
                  </Link>{" "}
                </li>
                <li>
                  {" "}
                  <Link to="#" className="google">
                    {" "}
                    <i className="fab fa-google" />{" "}
                  </Link>{" "}
                </li>
              </ul>
              {!userAvailability && (
                <div className="footer-offer">
                  <p>Signup and get exclusive offers and coupon codes</p>
                  <Link
                    to="/register"
                    className="btn-custom btn-sm shadow-none"
                  >
                    Sign Up
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Footer Bottom */}
      <div className="footer-bottom">
        <div className="container">
          <ul>
            <li>
              {" "}
              <Link to="/legal">Privacy Policy</Link>{" "}
            </li>
          </ul>
          <div className="footer-copyright">
            <div>
              <p>
                {" "}
                Copyright © 2024{" "}
                <a
                  href="https://jarnetsolutions.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  JarNet
                </a>{" "}
                All Rights Reserved.
              </p>
              <p>
                Build Date/Time: {build.date} UI v{build.branch} #{build.number}
              </p>
            </div>
            <Link to="#" className="back-to-top" onClick={() => scrollToTop()}>
              Back to top <i className="fas fa-chevron-up" />{" "}
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};
