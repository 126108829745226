import { yupResolver } from "@hookform/resolvers/yup";
import { object } from "yup";
import * as Yup from "yup";
import { EMAIL_REGEX, USA_PHONE_REGEX } from "../constants/FORMATS";
import {
  EMAIL,
  GENERIC_REQ_MESSAGE,
  PHONE,
} from "../constants/VALIDATIONMESSAGE";

export const editProfileResolver = yupResolver(
  object().shape({
    id: Yup.string().notRequired(),
    firstName: Yup.string().required(GENERIC_REQ_MESSAGE),
    middleName: Yup.string().notRequired(),
    lastName: Yup.string().required(GENERIC_REQ_MESSAGE),
    email: Yup.string()
      .required(GENERIC_REQ_MESSAGE)
      .matches(EMAIL_REGEX, EMAIL),
    phone: Yup.string()
      .required(GENERIC_REQ_MESSAGE)
      .matches(USA_PHONE_REGEX, PHONE),
    gender: Yup.string().notRequired(),
    dateOfBirth: Yup.string().notRequired(),
    billingAddressSameAsStreeAddress: Yup.boolean().notRequired(),
    billingAddress: Yup.object({
      address1: Yup.string().notRequired(),
      address2: Yup.string().notRequired(),
      city: Yup.string().notRequired(),
      postalCode: Yup.string().notRequired(),
      state: Yup.string().notRequired(),
    }).notRequired(),
    streetAddress: Yup.object({
      address1: Yup.string().notRequired(),
      address2: Yup.string().notRequired(),
      city: Yup.string().notRequired(),
      postalCode: Yup.string().notRequired(),
      state: Yup.string().notRequired(),
    }),
  })
);
