import { FC, PropsWithChildren, useEffect } from "react";
import { AuthenticateContext } from "./AuthenticateContext";
import { useLocation } from "react-router";
import { useVerifyUserSession } from "./useVerifyUserSession";
import { useAuth } from "../../hooks/useAuth/useAuth";

export const AuthenticateProvider: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const { userAvailability } = useAuth();
  const { refetch } = useVerifyUserSession();

  useEffect(() => {
    userAvailability && refetch();
  }, [location.pathname]);

  return (
    <AuthenticateContext.Provider value={{}}>
      {children}
    </AuthenticateContext.Provider>
  );
};
