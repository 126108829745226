export const dateFormat = "MM/DD/YYYY";
export const timeFormat = "HH:mm";
export const fullDateAndTimeFormat = "MM/DD/YYYY HH:mm";

export const USA_PHONE_MASK = "(000) 000-0000";
export const GENERIC_PHONE_MASK = "000000000000000";

export const AX_CARD_MASK = "0000 000000 00000";
export const GENERIC_CARD_MASK = "0000 0000 0000 0000";

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const USA_PHONE_REGEX = /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/;
export const GENERIC_PHONE_REGEX =
  /^(?:0\.(?:0[0-9]|[0-9]\d?)|[0-9]\d*(?:\.\d{1,2})?)(?:e[+-]?\d+)?$/;

export const SPECIAL_CHAR_REGEX = /[@$!%*#?&]+/;
export const UPPER_CHAR_REGEX = /[A-Z]+/;
export const LOWER_CHAR_REGEX = /[a-z]+/;
export const NUMBER_REGEX = /\d+/;
