import { IMaskInput } from "react-imask";
import { ReactElement } from "react-imask/dist/mixin";
import { forwardRef } from "react";
import { CustomProps } from "./types";
import classNames from "classnames";
import { get } from "react-hook-form";

export const InputFieldWithMask = forwardRef<ReactElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const {
      setValue,
      fieldId,
      errors,
      register,
      inputType = "text",
      placeholder,
      name,
      disabled,
      icon,
      ...other
    } = props;

    const clearValue = (id: string) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      (document.getElementById(id) as HTMLInputElement).value = "";
      setValue(name, "");
    };

    return (
      <div className="input-group" style={{ minHeight: "82px" }}>
        <IMaskInput
          unmask={false}
          onAccept={(value: any) => setValue(name, value)}
          {...other}
          ref={ref}
          type={inputType}
          name={name}
          placeholder={placeholder}
          className={classNames("form-control form-control-light", {
            "is-invalid": get(errors, fieldId),
          })}
          id={fieldId}
          {...register(fieldId, {
            required: "Required",
          })}
        />
        {icon && (
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">
              {icon}
            </span>
          </div>
        )}
        {!disabled && (
          <div className="input-group-prepend">
            <span
              className="input-group-text"
              id="basic-addon1"
              onClick={() => clearValue(fieldId)}
              style={{
                cursor: "pointer",
                opacity: "0.5",
                fontWeight: "600",
                fontSize: "16px",
              }}
            >
              X
            </span>
          </div>
        )}
        {get(errors, fieldId) && (
          <div className="invalid-feedback" style={{ textAlign: "start" }}>
            {get(errors, fieldId).message}
          </div>
        )}
      </div>
    );
  }
);
