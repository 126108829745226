import { Fragment } from "react";
import { Link } from "react-router-dom";
import Scrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "../../hooks/useRedux/useRedux";
import { cartSlice } from "../../redux/cart/cart.slice";

export const Cartlist = () => {
  const dispatch = useDispatch();
  const cartList = useSelector((a) => a.cart.list);
  const subtotal = useSelector((a) => a.cart.subtotal);

  const handleRemove = (item) => {
    dispatch(cartSlice.actions.removeItem(item));
  };

  const IncrementItem = (item) => {
    if (item.qty < 20) {
      dispatch(
        cartSlice.actions.updateQuantity({ ...item, qty: item.qty + 1 })
      );
    }
  };

  const DecreaseItem = (item) => {
    if (item.qty > 1) {
      dispatch(
        cartSlice.actions.updateQuantity({ ...item, qty: item.qty - 1 })
      );
    }
  };

  return (
    <Fragment>
      <div className="cart-sidebar-body">
        <Scrollbar className="cart-sidebar-scroll">
          {cartList.map((item, i) => (
            <div key={`cart-list-${i}`} className="cart-sidebar-item">
              <div className="media">
                <Link to="/menu-item/1">
                  <img
                    src={process.env.PUBLIC_URL + "/" + item.img}
                    alt={item.name}
                  />
                </Link>
                <div className="media-body">
                  <h5>
                    <Link to="/menu-item/1" title={item.name}>
                      {item.name}
                    </Link>
                  </h5>
                  <p>{item.shortdesc}</p>
                </div>
              </div>
              {item.size !== null && (
                <div className="media-body">
                  <div>Size: </div>
                  <div className="customize-size-wrapper">
                    {item.availableSizes?.map((e, i) => (
                      <div className="customize-size-container" key={i}>
                        <div
                          key={i}
                          className={
                            e.size === item.size
                              ? "customize-size active"
                              : "customize-size"
                          }
                          onClick={() => {
                            dispatch(
                              cartSlice.actions.updateSize({
                                newItem: {
                                  ...item,
                                  size: e.size,
                                  price:
                                    item.availableSizes.length > 0
                                      ? item.availableSizes.find(
                                          (item) => item.size === e.size
                                        ).price
                                      : item.price,
                                },
                                oldItem: item,
                              })
                            );
                          }}
                        >
                          {e.size}"
                        </div>

                        {e.size === item.size && (
                          <p
                            className="subtitle"
                            style={{ marginBottom: "20px" }}
                          >
                            {item.size === "10"
                              ? "Small"
                              : item.size === "12"
                              ? "Medium"
                              : item.size === "14"
                              ? "Large"
                              : "X-Large"}
                          </p>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className="media-body">
                <div>Quantity:</div>
                <div className="qty">
                  <span
                    className="qty-subtract"
                    onClick={() => DecreaseItem(item)}
                  >
                    <i className="fa fa-minus" />
                  </span>
                  <input type="text" name="clicks" value={item.qty} readOnly />
                  <span className="qty-add" onClick={() => IncrementItem(item)}>
                    <i className="fa fa-plus" />
                  </span>
                </div>
              </div>
              <div className="cart-sidebar-price">
                <div className="cart-price">
                  <div>Item Price: </div>
                  <span>
                    ${new Intl.NumberFormat().format(item.price.toFixed(2))}
                  </span>
                </div>
                <div className="cart-price">
                  <div>Total Price: </div>
                  <span>
                    $
                    {new Intl.NumberFormat().format(
                      (item.price * item.qty).toFixed(2)
                    )}
                  </span>
                </div>
              </div>
              <div className="close-btn" onClick={() => handleRemove(item)}>
                <span />
                <span />
              </div>
            </div>
          ))}
        </Scrollbar>
      </div>
      <div className="cart-sidebar-footer">
        <h4>
          Total:{" "}
          <span>${new Intl.NumberFormat().format(subtotal.toFixed(2))}</span>{" "}
        </h4>
        <button type="button" className="btn-custom">
          Checkout
        </button>
      </div>
    </Fragment>
  );
};
