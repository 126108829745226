import React from "react";
import { useAuth } from "../../hooks/useAuth/useAuth";
import { Link, useNavigate } from "react-router-dom";
import { useSignOutCustomer } from "../sections/profile/useSignOutCustomer";

export const ProfileButton = () => {
  const { user, userAvailability } = useAuth();
  const navigate = useNavigate();
  const signOut = useSignOutCustomer();

  const getFirstChar = () => {
    if (user) {
      return `${
        user.firstName.charAt(0).toUpperCase() +
        user.lastName.charAt(0).toUpperCase()
      }`;
    }
    return "";
  };

  return userAvailability ? (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "8px",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <span
          style={{
            color: "white",
            margin: "0px",
            lineHeight: "20px",
          }}
        >
          {user ? user.firstName + " " + user?.lastName : ""}
        </span>
        <span
          style={{
            color: "#ED4E53",
            margin: "0px",
            textDecoration: "underline",
            lineHeight: "20px",
            cursor: "pointer",
          }}
          onClick={() => {
            signOut.mutate()
          }}
        >
          Logout
        </span>
      </div>
      <div
        className="rounded-circle"
        style={{
          width: "40px",
          height: "40px",
          border: "2px solid #ED4E53",
          color: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => navigate("/profile")}
      >
        <span
          style={{
            color: "white",
            margin: "0px",
          }}
        >
          {getFirstChar()}
        </span>
      </div>
    </div>
  ) : (
    <Link to="/login">Login</Link>
  );
};
