import Moment from "moment";

export const useApp = () => {
  const isProduction = process.env.NODE_ENV === "production";

  const build = {
    branch: process.env.REACT_APP_BUILD_BRANCH || "0",
    number: Number(process.env.REACT_APP_BUILD_NUMBER),
    date: Moment(new Date(process.env.REACT_APP_BUILD_DATE || "")).format(
      "MM/DD/YYYY HH:mm"
    ),
  };

  const serverUrl = isProduction
    ? ""
    : "https://dev.jarnetsolutions.com";

  return {
    isProduction,
    build,
    serverUrl,
  };
};
