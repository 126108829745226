import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Auth } from "../../types/auth";

var user = localStorage.getItem("ninoz-web-user") || null;

const initialState: Auth = {
  user: user ? JSON.parse(user) : null,
  token: localStorage.getItem("ninoz-web-token") || null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signIn(state, action: PayloadAction<Auth>) {
      state.token = action.payload.token;
      state.user = action.payload.user;
    },
    signOut(state) {
      state.token = null;
      state.user = null;
      localStorage.removeItem("ninoz-web-user");
      localStorage.removeItem("ninoz-web-token");
    },
  },
});

export const actions = authSlice.actions;

export const reducer = authSlice.reducer;
