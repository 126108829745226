import { Link } from "react-router-dom";
import { InputField } from "../../layouts/InputField";
import { useForm } from "react-hook-form";
import { useSignInCustomer } from "./useSignInCustomer";
import { signInResolver } from "../../../validation/signInResolver";
import { useState } from "react";

export const Content = () => {
  const signIn = useSignInCustomer();
  const [showPassword, setShowPassword] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: signInResolver,
  });

  const onSubmit = handleSubmit(async (data) => {
    signIn.mutate(data);
  });

  return (
    <div className="section">
      <div className="imgs-wrapper">
        <img
          src={process.env.PUBLIC_URL + "/assets/img/veg/11.png"}
          alt="veg"
          className="d-none d-lg-block"
        />
        <img
          src={process.env.PUBLIC_URL + "/assets/img/prods/supreme-pizza.png"}
          alt="veg"
          className="d-none d-lg-block"
        />
      </div>
      <div className="container">
        <div className="auth-wrapper">
          <div
            className="auth-description bg-cover bg-center dark-overlay dark-overlay-2"
            style={{
              backgroundImage:
                "url(" + process.env.PUBLIC_URL + "/assets/img/auth.jpeg)",
            }}
          >
            <div className="auth-description-inner">
              <i className="flaticon-chili" />
              <h2>Ninoz Pizza & Pasta</h2>
              <p>
                500 N HWY 377 Suite 150 <br />
                Roanoke TX 7622
              </p>
            </div>
          </div>
          <div className="auth-form">
            <h2>Log in</h2>
            <form method="post" onSubmit={onSubmit}>
              <div className="form-group">
                <InputField
                  setValue={setValue}
                  fieldId="email"
                  errors={errors}
                  register={register}
                  inputType="text"
                  placeholder="Email"
                  name="email"
                />
              </div>
              <div className="form-group">
                <InputField
                  setValue={setValue}
                  fieldId="password"
                  errors={errors}
                  register={register}
                  inputType={showPassword ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  onClickIcon={() => setShowPassword(!showPassword)}
                  icon={
                    !showPassword ? (
                      <i className="fa fa-eye"></i>
                    ) : (
                      <i className="fa fa-eye-slash"></i>
                    )
                  }
                />
              </div>
              <div style={{ minHeight: "62px" }}>
                {signIn.error && (
                  <div className="alert alert-danger" role="alert">
                    Invalid user name and/or password
                  </div>
                )}
              </div>
              <Link to="#">Forgot Password?</Link>
              <button type="submit" className="btn-custom primary">
                {signIn.isLoading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  "Login"
                )}
              </button>
              <div className="auth-seperator">
                <span>OR</span>
              </div>
              {/*<div className="social-login">
                  <button type="button" className="ct-social-login facebook">
                    <i className="fab fa-facebook-f" /> Continue with Facebook{" "}
                  </button>
                  <button type="button" className="ct-social-login google">
                    <i className="fab fa-google" /> Continue with Google
                  </button>
                </div>*/}
              <p>
                Don't have an account? <Link to="/register">Sign Up</Link>{" "}
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
