import { Tab, Col, Nav, Row, Form } from "react-bootstrap";
import { useAuth } from "../../../hooks/useAuth/useAuth";
import { useForm } from "react-hook-form";
import { editProfileResolver } from "../../../validation/editProfileResolver";
import { InputField } from "../../layouts/InputField";
import { InputFieldWithMask } from "../../layouts/InputFieldWithMask";
import { useState } from "react";
import { usePhoneNumberFormatted } from "../profile/usePhoneNumberFormatted";
import { useSignOutCustomer } from "../profile/useSignOutCustomer";
import { useUpdateCustomer } from "../profile/useUpdateCustomer";
import { useNavigate } from "react-router-dom";

export const Content = () => {
  const { user } = useAuth();
  const signOut = useSignOutCustomer();
  const phoneFormatted = usePhoneNumberFormatted(user?.phone);
  const updateCustomer = useUpdateCustomer();
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: user?.id,
      firstName: user?.firstName,
      middleName: user?.middleName,
      lastName: user?.lastName,
      email: user?.email,
      gender: user?.gender ?? "",
      phone: phoneFormatted,
      dateOfBirth: user?.dateOfBirth,
      billingAddressSameAsStreeAddress:
        user?.billingAddressSameAsStreeAddress ?? true,
      billingAddress: {
        address1: user?.billingAddress?.address1 ?? "",
        address2: user?.billingAddress?.address2 ?? "",
        city: user?.billingAddress?.city ?? "",
        postalCode: user?.billingAddress?.postalCode ?? "",
        state: user?.billingAddress?.state ?? "",
      },
      streetAddress: {
        address1: user?.streetAddress?.address1 ?? "",
        address2: user?.streetAddress?.address2 ?? "",
        city: user?.streetAddress?.city ?? "",
        postalCode: user?.streetAddress?.postalCode ?? "",
        state: user?.streetAddress?.state ?? "",
      },
    },
    resolver: editProfileResolver,
  });

  const onSubmit = handleSubmit(async (data) => {
    updateCustomer.mutate({
      ...data,
      phone: data?.phone?.replace(/[^a-zA-Z0-9]/g, ""),
    });
  });

  const [gender, setGender] = useState(getValues("gender"));

  const handleChange = (e) => {
    e.persist();
    setGender(e.target.value);
    setValue("gender", e.target.value);
  };

  return (
    <div className="section">
      <div className="container">
        <div className="d-flex flex-nowrap justify-content-between">
          <h2>My Profile</h2>
          <div className="d-flex align-items-center gap-1">
            <span
              style={{
                color: "#ED4E53",
                margin: "0px",
                textDecoration: "underline",
                lineHeight: "20px",
                cursor: "pointer",
                paddingRight: "10px",
              }}
              onClick={() => {
                signOut.mutate();
              }}
            >
              Logout
            </span>
            <button
              className="btn-custom light"
              onClick={() => navigate("/profile")}
            >
              Cancel
            </button>
            <button className="btn-custom primary" onClick={onSubmit}>
              Save
            </button>
          </div>
        </div>
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="basicInformation"
        >
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="basicInformation">
                    Basic Information
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="orders">Orders</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="basicInformation">
                  <div className="flex-column">
                    <h4>Personal Information</h4>
                    <div className="row row-gap-1">
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <label
                          style={{ textAlign: "left", marginBottom: "0px" }}
                        >
                          First Name <span className="text-danger">*</span>
                        </label>
                        <InputField
                          maxlength="60"
                          setValue={setValue}
                          fieldId="firstName"
                          errors={errors}
                          register={register}
                          inputType="text"
                          placeholder="First Name"
                          name="firstName"
                        />
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <label
                          style={{ textAlign: "left", marginBottom: "0px" }}
                        >
                          Middle Name
                        </label>
                        <InputField
                          maxlength="60"
                          setValue={setValue}
                          fieldId="middleName"
                          errors={errors}
                          register={register}
                          inputType="text"
                          placeholder="Middle Name"
                          name="middleName"
                        />
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <label
                          style={{ textAlign: "left", marginBottom: "0px" }}
                        >
                          Last Name <span className="text-danger">*</span>
                        </label>
                        <InputField
                          maxlength="60"
                          setValue={setValue}
                          fieldId="lastName"
                          errors={errors}
                          register={register}
                          inputType="text"
                          placeholder="Last Name"
                          name="lastName"
                        />
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <label
                          style={{ textAlign: "left", marginBottom: "0px" }}
                        >
                          Email <span className="text-danger">*</span>
                        </label>
                        <InputField
                          maxlength="60"
                          setValue={setValue}
                          fieldId="email"
                          errors={errors}
                          register={register}
                          inputType="text"
                          placeholder="Email"
                          name="email"
                          disabled={true}
                        />
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <label
                          style={{ textAlign: "left", marginBottom: "0px" }}
                        >
                          Phone <span className="text-danger">*</span>
                        </label>
                        <InputFieldWithMask
                          setValue={setValue}
                          fieldId="phone"
                          errors={errors}
                          register={register}
                          inputType="phone"
                          placeholder="Phone"
                          name="phone"
                          mask="(000) 000-0000"
                          value={getValues("phone")}
                        />
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                        <label
                          style={{ textAlign: "left", marginBottom: "0px" }}
                        >
                          Date Of Birth
                        </label>
                        <InputFieldWithMask
                          setValue={setValue}
                          fieldId="dateOfBirth"
                          errors={errors}
                          register={register}
                          inputType="text"
                          placeholder="Date Of Birth (MM/YY)"
                          name="dateOfBirth"
                          mask="00/00"
                        />
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-4 col-sm-12">
                        <label
                          style={{ textAlign: "left", marginBottom: "0px" }}
                        >
                          Gender <span className="text-danger">*</span>
                        </label>
                        <Form.Group
                          controlId="pickup"
                          className="d-flex flex-row align-items-center gap-2"
                          style={{ minHeight: "82px" }}
                          {...register("gender")}
                        >
                          <Form.Check
                            value="m"
                            type="radio"
                            aria-label="radio 1"
                            label="Male"
                            onChange={handleChange}
                            checked={gender === "m"}
                          />
                          <Form.Check
                            value="f"
                            type="radio"
                            aria-label="radio 2"
                            label="Female"
                            onChange={handleChange}
                            checked={gender === "f"}
                          />
                          <Form.Check
                            value=""
                            type="radio"
                            aria-label="radio 3"
                            label="Undisclosed"
                            onChange={handleChange}
                            checked={gender === ""}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="flex-column">
                    <h4>Street Address</h4>
                    <div className="row row-gap-1">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <label
                          style={{ textAlign: "left", marginBottom: "0px" }}
                        >
                          Address 1
                        </label>
                        <InputField
                          maxlength="40"
                          setValue={setValue}
                          fieldId="streetAddress.address1"
                          errors={errors}
                          register={register}
                          inputType="text"
                          placeholder="Address 1"
                          name="streetAddress.address1"
                        />
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <label
                          style={{ textAlign: "left", marginBottom: "0px" }}
                        >
                          Address 2
                        </label>
                        <InputField
                          maxlength="40"
                          setValue={setValue}
                          fieldId="streetAddress.address2"
                          errors={errors}
                          register={register}
                          inputType="text"
                          placeholder="Address 2"
                          name="streetAddress.address2"
                        />
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                        <label
                          style={{ textAlign: "left", marginBottom: "0px" }}
                        >
                          City
                        </label>
                        <InputField
                          maxlength="20"
                          setValue={setValue}
                          fieldId="streetAddress.city"
                          errors={errors}
                          register={register}
                          inputType="text"
                          placeholder="City"
                          name="streetAddress.city"
                        />
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                        <label
                          style={{ textAlign: "left", marginBottom: "0px" }}
                        >
                          State/Province
                        </label>
                        <InputField
                          maxlength="2"
                          setValue={setValue}
                          fieldId="streetAddress.state"
                          errors={errors}
                          register={register}
                          inputType="text"
                          placeholder="State/Province"
                          name="streetAddress.state"
                        />
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                        <label
                          style={{ textAlign: "left", marginBottom: "0px" }}
                        >
                          Zip/Postal Code
                        </label>
                        <InputField
                          maxlength="5"
                          setValue={setValue}
                          fieldId="streetAddress.postalCode"
                          errors={errors}
                          register={register}
                          inputType="text"
                          placeholder="Zip/Postal Code"
                          name="streetAddress.postalCode"
                        />
                      </div>
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <input
                          {...register("billingAddressSameAsStreeAddress")}
                          name="billingAddressSameAsStreeAddress"
                          type="checkbox"
                        />
                        <label
                          style={{
                            textAlign: "left",
                            marginBottom: "0px",
                            paddingLeft: "4px",
                          }}
                        >
                          Billing Address Same As Stree Address
                        </label>
                      </div>
                    </div>
                  </div>
                  {watch("billingAddressSameAsStreeAddress") === false && (
                    <>
                      <hr />
                      <div className="flex-column">
                        <h4>Billing Address</h4>
                        <div className="row row-gap-1">
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <label
                              style={{ textAlign: "left", marginBottom: "0px" }}
                            >
                              Address 1
                            </label>
                            <InputField
                              maxlength="40"
                              setValue={setValue}
                              fieldId="billingAddress.address1"
                              errors={errors}
                              register={register}
                              inputType="text"
                              placeholder="Address 1"
                              name="billingAddress.address1"
                            />
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <label
                              style={{ textAlign: "left", marginBottom: "0px" }}
                            >
                              Address 2
                            </label>
                            <InputField
                              maxlength="40"
                              setValue={setValue}
                              fieldId="billingAddress.address2"
                              errors={errors}
                              register={register}
                              inputType="text"
                              placeholder="Address 2"
                              name="billingAddress.address2"
                            />
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                            <label
                              style={{ textAlign: "left", marginBottom: "0px" }}
                            >
                              City
                            </label>
                            <InputField
                              maxlength="20"
                              setValue={setValue}
                              fieldId="billingAddress.city"
                              errors={errors}
                              register={register}
                              inputType="text"
                              placeholder="City"
                              name="billingAddress.city"
                            />
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                            <label
                              style={{ textAlign: "left", marginBottom: "0px" }}
                            >
                              State/Province
                            </label>
                            <InputField
                              maxlength="2"
                              setValue={setValue}
                              fieldId="billingAddress.state"
                              errors={errors}
                              register={register}
                              inputType="text"
                              placeholder="State/Province"
                              name="billingAddress.state"
                            />
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                            <label
                              style={{ textAlign: "left", marginBottom: "0px" }}
                            >
                              Zip/Postal Code
                            </label>
                            <InputField
                              maxlength="5"
                              setValue={setValue}
                              fieldId="billingAddress.postalCode"
                              errors={errors}
                              register={register}
                              inputType="text"
                              placeholder="Zip/Postal Code"
                              name="billingAddress.postalCode"
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </Tab.Pane>
                <Tab.Pane eventKey="orders">
                  <h4>Orders</h4>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </div>
  );
};
