import { yupResolver } from "@hookform/resolvers/yup";
import { object } from "yup";
import * as Yup from "yup";
import { EMAIL_REGEX } from "../constants/FORMATS";
import { EMAIL, GENERIC_REQ_MESSAGE } from "../constants/VALIDATIONMESSAGE";

export const signInResolver = yupResolver(
  object().shape({
    email: Yup.string()
      .required(GENERIC_REQ_MESSAGE)
      .matches(EMAIL_REGEX, EMAIL),
    password: Yup.string().required(GENERIC_REQ_MESSAGE),
  })
);
