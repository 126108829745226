export const GENERIC_REQ_MESSAGE = "Required";
export const EMAIL = "Invalid email address";
export const MAX_LEN_128 = "Must be at most 128 characters";
export const MAX_LEN_40 = "Must be at most 40 characters";
export const MAX_LEN_10 = "Must be at most 10 characters";
export const MAX_LEN_4_DIGITS = "Must be at most 4 digits";
export const MIN_LEN_4_DIGITS = "Must be at least 4 digits";
export const MAX_LEN_3_DIGITS = "Must be at most 3 digits";
export const MIN_LEN_3_DIGITS = "Must be at least 3 digits";
export const MIN_LEN_8 = "Must be at least 8 characters";

export const INVALID_DATE = "Invalid Date";
export const PHONE = "Invalid phone number";
export const CARD_NUMBER = "Must be a valid card number";

export const SPECIAL_CHAR = "Need one special character";
export const UPPER_CHAR = "Need one uppercase character";
export const LOWER_CHAR = "Need one lowercase character";
export const NUMBER = "Need one number";
