import { Component, Fragment } from "react";
import { Banner } from "./Banner";
import { Categories } from "./Categories";
import Cta from "./Cta";
import Instagram from "./Instagram";
import Products from "./Products";
import Testimonials from "./Testimonials";
import Clear from "./Clear";

class Content extends Component {
  render() {
    return (
      <Fragment>
        <Banner />
        <Clear />
        <Categories />
        <div className="section products-section">
          <Products />
        </div>
        <Cta />
        <Testimonials />
        <Instagram />
      </Fragment>
    );
  }
}

export default Content;
