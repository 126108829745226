import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "../../../hooks/useRedux/useRedux";
import { useEffect, useState } from "react";
import { Collapse } from "react-bootstrap";
import { SignInCustomer } from "./SignInCustomer";
import { useAuth } from "../../../hooks/useAuth/useAuth";
import { InputField } from "../../layouts/InputField";
import { useForm } from "react-hook-form";
import { InputFieldWithMask } from "../../layouts/InputFieldWithMask";
import { useCreateOrder } from "./useCreateOrder";
import { paymentResolver } from "../../../validation/paymentResolver";
import { cartSlice } from "../../../redux/cart/cart.slice";
import { Form } from "react-bootstrap";
import { handleCardNumber } from "./handleCardNumber";

export const Content = () => {
  const { user, userAvailability } = useAuth();
  const { list, pickupOrDelivery, subtotal, deliveryCharge, totalTax, total } =
    useSelector((a) => a.cart);

  const [creditCard, setCreditCard] = useState(false);
  const createOrder = useCreateOrder();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: user?.id ?? null,
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      phone: user?.phone,
      companyName: "",
      billingAddressSameAsStreeAddress:
        user?.billingAddressSameAsStreeAddress ?? true,
      streetAddress: {
        address1: user?.streetAddress?.address1 ?? null,
        address2: user?.streetAddress?.address2 ?? null,
        city: user?.streetAddress?.city ?? null,
        postalCode: user?.streetAddress?.postalCode ?? null,
        state: user?.streetAddress?.state ?? null,
      },
      billingAddress: {
        address1: user?.billingAddress?.address1,
        address2: user?.billingAddress?.address2,
        city: user?.billingAddress?.city,
        postalCode: user?.billingAddress?.postalCode,
        state: user?.billingAddress?.state,
      },
      cardNumber: "",
      fullName:
        (user?.firstName ? user?.firstName + " " : "") + (user?.lastName ?? ""),
      expiryDate: "",
      cvv: "",
      orderNotes: "",
      formOfPaymentType: "PAYATSTORE",
    },
    resolver: paymentResolver(pickupOrDelivery, creditCard),
  });

  const onSubmit = handleSubmit(async (data) => {
    const items = [];
    list.forEach((item, i) => {
      items.push({
        item: i + 1,
        menuItemId: item.id,
        groupName: item.name,
        price: item.price,
        parameter: item.size,
        shortDescription: item.name,
        description: item.shortdesc,
        quantity: item.quantity,
        note: "",
      });
    });
    createOrder.mutate(
      {
        order: {
          items: items,
          delivery: pickupOrDelivery === "delivery" ? deliveryCharge : 0,
          subtotal: subtotal,
          tax: totalTax,
          total: total,
          totalExtraCharges: 0,
        },
        customer: {
          id: data.id,
          firstName: data.firstName,
          lastName: data.lastName,
          phone: data.phone,
          email: data.email,
          ...(pickupOrDelivery === "delivery" && {
            streetAddress: data.streetAddress,
          }),
          ...(pickupOrDelivery === "delivery" && {
            billingAddress: data.billingAddressSameAsStreeAddress
              ? data.streetAddress
              : data.billingAddress,
          }),
        },
        payment: {
          formOfPaymentType: data.formOfPaymentType,
          notes: data.orderNotes,
          ...(data.formOfPaymentType !== "PAYATSTORE" && {
            cardNumber: data.cardNumber,
          }),
          ...(data.formOfPaymentType !== "PAYATSTORE" && {
            expireDate: data.expiryDate,
          }),
          ...(data.formOfPaymentType !== "PAYATSTORE" && {
            nameOnCard: data.fullName,
          }),
          ...(data.formOfPaymentType !== "PAYATSTORE" && { cvv: data.cvv }),
        },
      },
      {
        onSuccess: (data) => {
          navigate("/confirmation");
          sessionStorage.setItem(
            "ninoz-web-confirmation",
            JSON.stringify(data)
          );
          dispatch(cartSlice.actions.resetCart());
        },
      }
    );
  });

  const handlePaymentMethod = (name) => {
    setValue("formOfPaymentType", name);
    if (name === "CREDITCARD") {
      setCreditCard(true);
    } else {
      setCreditCard(false);
    }
  };

  const applePayButton = () => {
    const request = {
      countryCode: "US",
      currencyCode: "USD",
      merchantCapabilities: ["supports3DS"],
      supportedNetworks: ["visa", "masterCard", "amex", "discover"],
      total: {
        label: "Ninoz Pizza apple pay",
        amount: new Intl.NumberFormat().format(subtotal.toFixed(2)),
      },
    };
    var session = new window.ApplePaySession(10, request);
    session.begin();

    try {
      session.onvalidatemerchant = function (event) {
        console.log(
          "starting session.onvalidatemerchant" + JSON.stringify(event)
        );
        alert("starting session.onvalidatemerchant" + JSON.stringify(event));

        var promise = session.performValidation(event.validationURL);

        promise.then(function (merchantSession) {
          alert("Merchant Session: " + JSON.stringify(merchantSession));
          console.log("Merchant Session: " + JSON.stringify(merchantSession));
          session.completeMerchantValidation(merchantSession);
        });
      };
    } catch (error) {
      console.log("On Validate Merchant Error: " + error);
    }

    try {
      console.log("onpaymentmethodselected");
      alert("onpaymentmethodselected");

      session.onpaymentmethodselected = function (event) {
        console.log("In On Payment Method Selected");
        alert("In On Payment Method Selected");
        const update = {};
        session.completePaymentMethodSelection(update);
      };
    } catch (error) {
      console.log("On Payment Method Selected Error: " + error);
    }

    try {
      console.log("onpaymentauthorized");
      alert("onpaymentauthorized");
      session.onpaymentauthorized = function (event) {
        console.log("starting session.onpaymentauthorized");
        alert("starting session.onpaymentauthorized");

        var applePaymentToken = event.payment.token;

        console.log("Token" + applePaymentToken);
        alert("Token" + applePaymentToken);
        session.completePayment(session.STATUS_SUCCESS);
      };
    } catch (error) {
      alert("On Payment Authorized Error: " + error);
      console.log("On Payment Authorized Error: " + error);
    }

    try {
      session.oncancel = function (event) {
        alert("starting session.oncancel" + JSON.stringify(event));
        console.log("starting session.oncancel" + JSON.stringify(event));
      };
    } catch (error) {
      alert("On Cancel Error: " + error);
      console.log("On Cancel Error: " + error);
    }
  };

  const handleChange = (e) => {
    e.persist();
    dispatch(cartSlice.actions.PickupOrDelivery(e.target.value));
  };

  useEffect(() => {
    document
      .getElementsByTagName("apple-pay-button")[0]
      ?.addEventListener("click", function () {
        applePayButton();
      });
  }, []);

  return (
    <section className="section">
      <div className="container">
        <form method="post" onSubmit={onSubmit}>
          <div className="row">
            <div className="col-xl-7">
              {/* Login */}

              {!userAvailability && <SignInCustomer />}

              <div className="ct-notice-content d-block">
                <p>If you have a coupon code, apply it below</p>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Coupon Code"
                  />
                  <div className="input-group-append">
                    <button className="btn-custom shadow-none" type="button">
                      Apply Code
                    </button>
                  </div>
                </div>
              </div>
              {/* Buyer Info */}
              <h4>Personal Information</h4>
              <div className="row">
                <div className="form-group col-xl-6">
                  <label style={{ textAlign: "left", marginBottom: "0px" }}>
                    First Name <span className="text-danger">*</span>
                  </label>
                  <InputField
                    maxLength="60"
                    setValue={setValue}
                    fieldId="firstName"
                    errors={errors}
                    register={register}
                    inputType="text"
                    placeholder="First Name"
                    name="firstName"
                  />
                </div>
                <div className="form-group col-xl-6">
                  <label style={{ textAlign: "left", marginBottom: "0px" }}>
                    Last Name <span className="text-danger">*</span>
                  </label>
                  <InputField
                    maxLength="60"
                    setValue={setValue}
                    fieldId="lastName"
                    errors={errors}
                    register={register}
                    inputType="text"
                    placeholder="Last Name"
                    name="lastName"
                  />
                </div>
                <div className="form-group col-xl-6">
                  <label style={{ textAlign: "left", marginBottom: "0px" }}>
                    Email <span className="text-danger">*</span>
                  </label>
                  <InputField
                    maxLength="60"
                    setValue={setValue}
                    fieldId="email"
                    errors={errors}
                    register={register}
                    inputType="text"
                    placeholder="Email"
                    name="email"
                  />
                </div>
                <div className="form-group col-xl-6">
                  <label style={{ textAlign: "left", marginBottom: "0px" }}>
                    Phone <span className="text-danger">*</span>
                  </label>
                  <InputFieldWithMask
                    setValue={setValue}
                    fieldId="phone"
                    errors={errors}
                    register={register}
                    inputType="phone"
                    placeholder="Phone"
                    name="phone"
                    mask="(000) 000-0000"
                    value={watch("phone")}
                  />
                </div>
                <div className="form-group col-xl-12">
                  <label style={{ textAlign: "left", marginBottom: "0px" }}>
                    Company Name
                  </label>
                  <InputField
                    maxLength="60"
                    setValue={setValue}
                    fieldId="companyName"
                    errors={errors}
                    register={register}
                    inputType="text"
                    placeholder="Company Name"
                    name="companyName"
                  />
                </div>
                <div className="form-group col-xl-12">
                  <label style={{ textAlign: "left", marginBottom: "0px" }}>
                    Order Notes
                  </label>
                  <InputField
                    maxLength="60"
                    setValue={setValue}
                    fieldId="orderNotes"
                    errors={errors}
                    register={register}
                    inputType="text"
                    placeholder="Order Notes"
                    name="orderNotes"
                  />
                </div>
                <div className="form-group col-xl-12">
                  <label style={{ textAlign: "left", marginBottom: "0px" }}>
                    Pickup/Delivery
                  </label>
                  <Form.Group controlId="pickup" class="d-flex flex-row gap-5">
                    <Form.Check
                      value="pickup"
                      type="radio"
                      aria-label="radio 1"
                      label="Pickup"
                      class="custom-radio-input"
                      onChange={handleChange}
                      checked={pickupOrDelivery === "pickup"}
                    />
                    <div class="d-flex flex-row gap-2">
                      <Form.Check
                        value="delivery"
                        type="radio"
                        aria-label="radio 2"
                        label="Delivery"
                        class="custom-radio-input"
                        onChange={handleChange}
                        checked={pickupOrDelivery === "delivery"}
                      />
                      <span>
                        $
                        {new Intl.NumberFormat().format(
                          deliveryCharge.toFixed(2)
                        )}
                      </span>{" "}
                    </div>
                  </Form.Group>
                </div>
              </div>
              {pickupOrDelivery === "delivery" && (
                <>
                  <>
                    <hr />
                    <h4>Street Details</h4>
                    <div className="row">
                      <div className="form-group col-xl-6">
                        <label
                          style={{ textAlign: "left", marginBottom: "0px" }}
                        >
                          Address 1
                        </label>
                        <InputField
                          maxLength="40"
                          setValue={setValue}
                          fieldId="streetAddress.address1"
                          errors={errors}
                          register={register}
                          inputType="text"
                          placeholder="Address 1"
                          name="streetAddress.address1"
                        />
                      </div>
                      <div className="form-group col-xl-6">
                        <label
                          style={{ textAlign: "left", marginBottom: "0px" }}
                        >
                          Address 2
                        </label>
                        <InputField
                          maxLength="40"
                          setValue={setValue}
                          fieldId="streetAddress.address2"
                          errors={errors}
                          register={register}
                          inputType="text"
                          placeholder="Address 2"
                          name="streetAddress.address2"
                        />
                      </div>
                      <div className="form-group col-xl-4">
                        <label
                          style={{ textAlign: "left", marginBottom: "0px" }}
                        >
                          City
                        </label>
                        <InputField
                          maxLength="20"
                          setValue={setValue}
                          fieldId="streetAddress.city"
                          errors={errors}
                          register={register}
                          inputType="text"
                          placeholder="City"
                          name="streetAddress.city"
                        />
                      </div>
                      <div className="form-group col-xl-4">
                        <label
                          style={{ textAlign: "left", marginBottom: "0px" }}
                        >
                          State/Province
                        </label>
                        <InputField
                          maxLength="2"
                          setValue={setValue}
                          fieldId="streetAddress.state"
                          errors={errors}
                          register={register}
                          inputType="text"
                          placeholder="State/Province"
                          name="streetAddress.state"
                        />
                      </div>
                      <div className="form-group col-xl-4">
                        <label
                          style={{ textAlign: "left", marginBottom: "0px" }}
                        >
                          Zip/Postal Code
                        </label>
                        <InputField
                          maxLength="5"
                          setValue={setValue}
                          fieldId="streetAddress.postalCode"
                          errors={errors}
                          register={register}
                          inputType="text"
                          placeholder="Zip/Postal Code"
                          name="streetAddress.postalCode"
                        />
                      </div>
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <input
                          {...register("billingAddressSameAsStreeAddress")}
                          name="billingAddressSameAsStreeAddress"
                          type="checkbox"
                        />
                        <label
                          style={{
                            textAlign: "left",
                            marginBottom: "0px",
                            paddingLeft: "4px",
                          }}
                        >
                          Billing Address Same As Stree Address
                        </label>
                      </div>
                    </div>
                  </>
                  <>
                    {watch("billingAddressSameAsStreeAddress") === false && (
                      <>
                        <hr />
                        <h4>Billing Details</h4>
                        <div className="row">
                          <div className="form-group col-xl-6">
                            <label
                              style={{ textAlign: "left", marginBottom: "0px" }}
                            >
                              Address 1
                            </label>
                            <InputField
                              maxLength="40"
                              setValue={setValue}
                              fieldId="billingAddress.address1"
                              errors={errors}
                              register={register}
                              inputType="text"
                              placeholder="Address 1"
                              name="billingAddress.address1"
                            />
                          </div>
                          <div className="form-group col-xl-6">
                            <label
                              style={{ textAlign: "left", marginBottom: "0px" }}
                            >
                              Address 2
                            </label>
                            <InputField
                              maxLength="40"
                              setValue={setValue}
                              fieldId="billingAddress.address2"
                              errors={errors}
                              register={register}
                              inputType="text"
                              placeholder="Address 2"
                              name="billingAddress.address2"
                            />
                          </div>
                          <div className="form-group col-xl-4">
                            <label
                              style={{ textAlign: "left", marginBottom: "0px" }}
                            >
                              City
                            </label>
                            <InputField
                              maxLength="20"
                              setValue={setValue}
                              fieldId="billingAddress.city"
                              errors={errors}
                              register={register}
                              inputType="text"
                              placeholder="City"
                              name="billingAddress.city"
                            />
                          </div>
                          <div className="form-group col-xl-4">
                            <label
                              style={{ textAlign: "left", marginBottom: "0px" }}
                            >
                              State/Province
                            </label>
                            <InputField
                              maxLength="2"
                              setValue={setValue}
                              fieldId="billingAddress.state"
                              errors={errors}
                              register={register}
                              inputType="text"
                              placeholder="State/Province"
                              name="billingAddress.state"
                            />
                          </div>
                          <div className="form-group col-xl-4">
                            <label
                              style={{ textAlign: "left", marginBottom: "0px" }}
                            >
                              Zip/Postal Code
                            </label>
                            <InputField
                              maxLength="5"
                              setValue={setValue}
                              fieldId="billingAddress.postalCode"
                              errors={errors}
                              register={register}
                              inputType="text"
                              placeholder="Zip/Postal Code"
                              name="billingAddress.postalCode"
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                </>
              )}
              {/* /Buyer Info */}
            </div>

            <div className="col-xl-5 checkout-billing">
              {/* Order Details Start */}
              <table className="ct-responsive-table">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Qunantity</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {list.map((item, i) => (
                    <tr key={`item-${i}`}>
                      <td data-title="Product">
                        <div className="cart-product-wrapper">
                          <div className="cart-product-body">
                            <h6>{item.name}</h6>
                          </div>
                        </div>
                      </td>
                      <td data-title="Quantity">x{item.qty}</td>
                      <td data-title="Total">
                        <strong>
                          $
                          {new Intl.NumberFormat().format(
                            (item.price * item.qty).toFixed(2)
                          )}
                        </strong>
                      </td>
                    </tr>
                  ))}
                  <tr className="total">
                    <td>
                      <h6 className="mb-0">Tax</h6>
                    </td>
                    <td />
                    <td>
                      <strong>${ Number.parseFloat(totalTax).toFixed(2)}</strong>
                    </td>
                  </tr>
                  {pickupOrDelivery === "delivery" && (
                    <tr className="total">
                      <td>
                        <h6 className="mb-0">Delivery Charge</h6>
                      </td>
                      <td />
                      <td>
                        <strong>${deliveryCharge}</strong>
                      </td>
                    </tr>
                  )}
                  <tr className="total">
                    <td>
                      <h6 className="mb-0">Grand Total</h6>
                    </td>
                    <td />
                    <td>
                      <strong>${total.toFixed(2)}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="d-flex flex-column gap-2 mb-3">
                <h4>Payment Method</h4>
                {/*}
                <apple-pay-button
                  buttonstyle="black"
                  type="plain"
                  locale="en-US"
                ></apple-pay-button>
                <GooglePayButton
                  buttonType="plain"
                  environment="TEST"
                  style={{
                    minWidth: "100%",
                  }}
                  buttonLocale="en-US"
                  buttonRadius="5px"
                  paymentRequest={{
                    apiVersion: 2,
                    apiVersionMinor: 0,
                    allowedPaymentMethods: [
                      {
                        type: "CARD",
                        parameters: {
                          allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                          allowedCardNetworks: ["MASTERCARD", "VISA"],
                        },
                        tokenizationSpecification: {
                          type: "PAYMENT_GATEWAY",
                          parameters: {
                            gateway: "example",
                            gatewayMerchantId: "exampleGatewayMerchantId",
                          },
                        },
                      },
                    ],
                    merchantInfo: {
                      merchantId: "12345678901234567890",
                      merchantName: "Demo Merchant",
                    },
                    transactionInfo: {
                      totalPriceStatus: "FINAL",
                      totalPriceLabel: "Total",
                      totalPrice: "100.00",
                      currencyCode: "USD",
                      countryCode: "US",
                    },
                  }}
                  onLoadPaymentData={(paymentRequest) => {
                    console.log("load payment data", paymentRequest);
                  }}
                />
                <PayPalButtons fundingSource={FUNDING.PAYPAL} />
                */}
                <Form.Group controlId="pickup" class="d-flex flex-column gap-2">
                  <Form.Check
                    value="PAYATSTORE"
                    type="radio"
                    aria-label="radio 2"
                    label={
                      <div
                        className="credit-card-button"
                        onClick={() => handlePaymentMethod("PAYATSTORE")}
                      >
                        $<span>Pay at store</span>
                      </div>
                    }
                    class="custom-radio-input"
                    onChange={() => handlePaymentMethod("PAYATSTORE")}
                    checked={watch("formOfPaymentType") === "PAYATSTORE"}
                  />
                  <Form.Check
                    value="CREDITCARD"
                    type="radio"
                    aria-label="radio 1"
                    label={
                      <div
                        className="credit-card-button"
                        onClick={() => handlePaymentMethod("CREDITCARD")}
                      >
                        <i className="fa fa-credit-card" />
                        <span>Debit or Credit Card</span>
                      </div>
                    }
                    class="custom-radio-input"
                    onChange={() => handlePaymentMethod("CREDITCARD")}
                    checked={watch("formOfPaymentType") === "CREDITCARD"}
                  />
                </Form.Group>
              </div>
              <Collapse in={creditCard}>
                <div id="example-collapse-text">
                  <div className="form-group text-end">
                    <button
                      type="button"
                      className="btn-close"
                      onClick={() => {
                        setCreditCard(false);
                        setValue("formOfPaymentType", "PAYATSTORE");
                      }}
                    >
                      <span />
                      <span />
                    </button>
                  </div>
                  <div className="form-group">
                    <label style={{ textAlign: "left", marginBottom: "0px" }}>
                      Card Number{" "}
                      {creditCard && <span className="text-danger">*</span>}
                    </label>
                    <InputFieldWithMask
                      value={watch("cardNumber")}
                      maxLength="20"
                      setValue={setValue}
                      fieldId="cardNumber"
                      errors={errors}
                      register={register}
                      inputType="text"
                      placeholder="Card Number"
                      name="cardNumber"
                      mask={handleCardNumber(watch("cardNumber")).mask}
                      icon={
                        <img
                          src={handleCardNumber(watch("cardNumber")).icon}
                          alt={
                            handleCardNumber(watch("cardNumber")).identerifier
                          }
                          width="40px"
                        />
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label style={{ textAlign: "left", marginBottom: "0px" }}>
                      Full Name{" "}
                      {creditCard && <span className="text-danger">*</span>}
                    </label>
                    <InputField
                      maxLength="60"
                      setValue={setValue}
                      fieldId="fullName"
                      errors={errors}
                      register={register}
                      inputType="text"
                      placeholder="Full Name"
                      name="fullName"
                    />
                  </div>
                  <div className="row">
                    <div className="col-xl-6 form-group">
                      <label style={{ textAlign: "left", marginBottom: "0px" }}>
                        Expiry Date{" "}
                        {creditCard && <span className="text-danger">*</span>}
                      </label>
                      <InputFieldWithMask
                        value={watch("expiryDate")}
                        setValue={setValue}
                        fieldId="expiryDate"
                        errors={errors}
                        register={register}
                        inputType="text"
                        placeholder="Expiry Date (MM/YY)"
                        name="expiryDate"
                        mask="00/00"
                      />
                    </div>
                    <div className="col-xl-6 form-group">
                      <label style={{ textAlign: "left", marginBottom: "0px" }}>
                        CVV{" "}
                        {creditCard && <span className="text-danger">*</span>}
                      </label>
                      <InputField
                        maxLength={
                          handleCardNumber(watch("cardNumber")).CVVLength
                        }
                        setValue={setValue}
                        fieldId="cvv"
                        errors={errors}
                        register={register}
                        inputType="text"
                        placeholder="CVV"
                        name="cvv"
                      />
                    </div>
                  </div>
                </div>
              </Collapse>
              <p className="small">
                Your personal data will be used to process your order, support
                your experience throughout this website, and for other purposes
                described in our
                <Link className="btn-link" to="/legal">
                  privacy policy.
                </Link>
              </p>
              <div style={{ minHeight: "62px" }}>
                {createOrder.error && (
                  <div className="alert alert-danger" role="alert">
                    {createOrder.error}
                  </div>
                )}
              </div>
              <button type="submit" className="btn-custom primary btn-block">
                Place Order
              </button>
              {/* Order Details End */}
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};
