export const Content = () => {
  return (
    <div className="contact-wrapper">
      <div className="ct-contact-map-wrapper">
        <iframe
          title="Ninoz Pizza & Pasta"
          loading="lazy"
          width="100%"
          height="100%"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          src="https://maps.google.com/maps?width=100%25&amp;height=100%25&amp;hl=en&amp;q=500%20N%20US%20Hwy%20377%20Ste%20F,%20Roanoke,%20TX%2076262,%20United%20States+(Ninoz%20Pizza%20&amp;%20Pasta)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        >
          <a href="https://www.gps.ie/">gps vehicle tracker</a>
        </iframe>
      </div>
      <div>
        <div className="section section-padding">
          <div className="container">
            <div className="contact-info">
              <div className="row">
                <div className="col-xl-6">
                  <div className="ct-info-box">
                    <i className="flaticon-location" />
                    <h5>Find Us</h5>
                    <span>500 N HWY 377 Suite 150</span>
                    <span>Roanoke TX 7622</span>
                    <span>
                      <a rel="external" href="tel: +1 (682) 502-4088">
                        +1 (682) 502-4088
                      </a>
                    </span>
                    <span>
                      <a rel="external" href="mailto: ninoz.pizza@gmail.com">
                        ninoz.pizza@gmail.com
                      </a>
                    </span>
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="ct-info-box">
                    <i className="flaticon-online-booking" />
                    <h5>Opening Hours</h5>
                    <span>
                      <span>Mon - Wed:</span> 11:00am - 9:00pm
                    </span>
                    <span>
                      <span>Thu:</span> 11:00am - 9:00pm
                    </span>
                    <span>
                      <span>Fri:</span> 11:00am - 9:00pm
                    </span>
                    <span>
                      <span>Sat - Sun:</span> 11:00am - 9:00pm
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section pt-0">
          <div className="container">
            <div className="section-title-wrap">
              <h2 className="title">Send us a Message </h2>
              <p className="subtitle">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. There are many variations of passages
              </p>
            </div>
            <form>
              <div className="row">
                <div className="form-group col-lg-6">
                  <input
                    type="text"
                    placeholder="First Name"
                    className="form-control"
                    name="fname"
                  />
                </div>
                <div className="form-group col-lg-6">
                  <input
                    type="text"
                    placeholder="Last Name"
                    className="form-control"
                    name="lname"
                  />
                </div>
                <div className="form-group col-lg-12">
                  <input
                    type="email"
                    placeholder="Email Address"
                    className="form-control"
                    name="email"
                  />
                </div>
                <div className="form-group col-lg-12">
                  <input
                    type="text"
                    placeholder="Subject"
                    className="form-control"
                    name="subject"
                  />
                </div>
                <div className="form-group col-lg-12">
                  <textarea
                    name="message"
                    className="form-control"
                    placeholder="Type your message"
                    rows={8}
                  />
                </div>
              </div>
              <button
                type="submit"
                className="btn-custom primary"
                name="button"
                onClick={(e) => e.preventDefault()}
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
