import React, { Component } from "react";
import { Link } from "react-router-dom";

class Breadcrumb extends Component {
  render() {
    return (
      <div
        className="subheader dark-overlay dark-overlay-2"
        style={{
          backgroundImage:
            "url(" + process.env.PUBLIC_URL + "/assets/img/subheader.png)",
        }}
      >
        <div className="container">
          <div className="subheader-inner">
            <h1>{this.props.breadcrumb.pagename}</h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                {this.props.breadcrumb.subpage && (
                  <li className="breadcrumb-item">
                    <Link to={this.props.breadcrumb.subpage.link}>
                      {this.props.breadcrumb.subpage.name}
                    </Link>
                  </li>
                )}
                <li className="breadcrumb-item active" aria-current="page">
                  {this.props.breadcrumb.pagename}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

export default Breadcrumb;
