import { axios } from "../../libs/axios";
import { SignInCustomerInput, SignInResponse } from "../../types";

export const signInCustomer = async (
  input: SignInCustomerInput
): Promise<SignInResponse> => {
  const response = await axios.post<SignInResponse>("/customer/signin", {
    email: input.email,
    password: input.password,
  });
  return response.data;
};
