import { useMutation } from "@tanstack/react-query";
import { signUpCustomer } from "../../../api/auth/signUpCustomer";
import { SignUpCustomerInput } from "../../../types";

export const useSignUpCustomer = () => {
  return useMutation<any, string, SignUpCustomerInput>(
    ["sign-up-customer"],
    signUpCustomer
  );
};
