import { useState } from "react";
import menu from "../../../data/homeMenu.json";
import { Link } from "react-router-dom";

export const Categories = () => {
  const [size, setSize] = useState("12");

  return (
    <div className="ct-categories">
      {/* Category Start */}
      <div className="ct-category">
        <div
          className="ct-category-bg"
          style={{
            backgroundImage:
              "url(" +
              process.env.PUBLIC_URL +
              "/assets/img/categories-lg/pizza-home.jpeg)",
          }}
        />
        <div className="ct-category-content">
          <div className="ct-category-content-inner">
            <div className="section-title-wrap section-header">
              <h5 className="custom-primary">{menu.pizzaMenu.title}</h5>
              <h2 className="title">{menu.pizzaMenu.subTitle}</h2>
              <p className="subtitle">{menu.pizzaMenu.description}</p>
            </div>
            <div className="customize-size-wrapper">
              <h5>Size: </h5>
              {menu.pizzaMenu.sizes.map((item, i) => (
                <div className="customize-size-container">
                  <div
                    key={i}
                    className={
                      size === item ? "customize-size active" : "customize-size"
                    }
                    onClick={() => setSize(item)}
                  >
                    {item}"
                  </div>

                  {size === item && (
                    <p className="subtitle" style={{ marginBottom: "20px" }}>
                      {size === "10"
                        ? "Small"
                        : size === "12"
                        ? "Medium"
                        : size === "14"
                        ? "Large"
                        : "X-Large"}
                    </p>
                  )}
                </div>
              ))}
            </div>
            {/* Menu Start */}
            <div className="row">
              {menu.pizzaMenu.items.map((item, i) => (
                <div key={i} className="col-lg-6">
                  <div className="ct-mini-menu-item">
                    <div className="ct-mini-menu-top">
                      <Link
                        to={`/menu-item/${item.id}`}
                        className="fa fa-plus plus-icon"
                      ></Link>
                      <h5>{item.title}</h5>
                      <div className="ct-mini-menu-dots" />
                      <span className="custom-primary">
                        $
                        {new Intl.NumberFormat().format(
                          (size === "10"
                            ? item.sPrice
                            : size === "12"
                            ? item.mPrice
                            : size === "14"
                            ? item.lPrice
                            : item.xlPrice
                          ).toFixed(2)
                        )}
                      </span>
                    </div>
                    <div className="ct-mini-menu-bottom">
                      <p>{item.shortdesc}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* Menu End */}
          </div>
        </div>
      </div>
      {/* Category End */}
      {/* Category Start */}
      <div className="ct-category">
        <div className="ct-category-content">
          <div className="ct-category-content-inner">
            <div className="section-title-wrap section-header">
              <h5 className="custom-primary">{menu.pastaMenu.title}</h5>
              <h2 className="title">{menu.pastaMenu.subTitle}</h2>
              <p className="subtitle">{menu.pastaMenu.description}</p>
            </div>
            {/* Menu Start */}
            <div className="row">
              {menu.pastaMenu.items.map((item, i) => (
                <div key={i} className="col-lg-6">
                  <div className="ct-mini-menu-item">
                    <div className="ct-mini-menu-top">
                      <Link
                        to={`/menu-item/${item.id}`}
                        className="fa fa-plus plus-icon"
                      ></Link>
                      <h5>{item.title}</h5>
                      <div className="ct-mini-menu-dots" />
                      <span className="custom-primary">
                        ${new Intl.NumberFormat().format(item.price.toFixed(2))}
                      </span>
                    </div>
                    <div className="ct-mini-menu-bottom">
                      <p>{item.shortdesc}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* Menu End */}
          </div>
        </div>
        <div
          className="ct-category-bg"
          style={{
            backgroundImage:
              "url(" +
              process.env.PUBLIC_URL +
              "/assets/img/categories-lg/pasta-home.png)",
          }}
        />
      </div>
      {/* Category End */}
      {/* Category Start */}
      <div className="ct-category">
        <div
          className="ct-category-bg"
          style={{
            backgroundImage:
              "url(" +
              process.env.PUBLIC_URL +
              "/assets/img/categories-lg/chicken-wing-home.png)",
          }}
        />
        <div className="ct-category-content">
          <div className="ct-category-content-inner">
            <div className="section-title-wrap section-header">
              <h5 className="custom-primary">
                {menu.subsAndChickenWingMenu.title}
              </h5>
            </div>
            {/* Menu Start */}
            <div className="row">
              {menu.subsAndChickenWingMenu.items.map((item, i) => (
                <div key={i} className="col-lg-6">
                  <div className="ct-mini-menu-item">
                    <div className="ct-mini-menu-top">
                      <Link
                        to={`/menu-item/${item.id}`}
                        className="fa fa-plus plus-icon"
                      ></Link>
                      <h5>{item.title}</h5>
                      <div className="ct-mini-menu-dots" />
                      <span className="custom-primary">
                        ${new Intl.NumberFormat().format(item.price.toFixed(2))}
                      </span>
                    </div>
                    <div className="ct-mini-menu-bottom">
                      <p>{item.shortdesc}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* Menu End */}
          </div>
        </div>
      </div>
      {/* Category End */}
      {/* Category Start */}
      <div className="ct-category">
        <div className="ct-category-content">
          <div className="ct-category-content-inner">
            <div className="section-title-wrap section-header">
              <h5 className="custom-primary">{menu.saladMenu.title}</h5>
            </div>
            {/* Menu Start */}
            <div className="row">
              {menu.saladMenu.items.map((item, i) => (
                <div key={i} className="col-lg-6">
                  <div className="ct-mini-menu-item">
                    <div className="ct-mini-menu-top">
                      <Link
                        to={`/menu-item/${item.id}`}
                        className="fa fa-plus plus-icon"
                      ></Link>
                      <h5>{item.title}</h5>
                      <div className="ct-mini-menu-dots" />
                      <span className="custom-primary">
                        ${new Intl.NumberFormat().format(item.price.toFixed(2))}
                      </span>
                    </div>
                    <div className="ct-mini-menu-bottom">
                      <p>{item.shortdesc}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* Menu End */}
          </div>
        </div>
        <div
          className="ct-category-bg"
          style={{
            backgroundImage:
              "url(" +
              process.env.PUBLIC_URL +
              "/assets/img/categories-lg/salad-home.png)",
          }}
        />
      </div>
      {/* Category End */}
    </div>
  );
};
