import Moment from "moment";
import { useNavigate } from "react-router-dom";

export const Content = () => {
  const navigate = useNavigate();

  const confirmationData =
    sessionStorage.getItem("ninoz-web-confirmation") || null;

  const data = JSON.parse(confirmationData);

  const onSubmit = () => {
    navigate("/");
  };

  return (
    <div className="section">
      <div className="container">
        <div className="confirmation-wrapper">
          <h2>Confirmation</h2>
          <img
            src={process.env.PUBLIC_URL + "/assets/img/verified-icon.png"}
            alt="verified-icon"
            width="50px"
            height="50px"
          />
          <span>
            Hi {data.customer.firstName + " " + data.customer.lastName},
          </span>
          <h4>Thank you for your Order!</h4>
          <div className="row row-gap-1" style={{ width: "100%" }}>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <DetailsItem name="Order Number" value1="" />
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <DetailsItem
                name="Order Date"
                value1={Moment(
                  new Date(data.order.createDateTime || "")
                ).format("MM/DD/YYYY HH:mm")}
              />
            </div>

            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <DetailsItem name="Payment Method" value1={data.payment.formOfPaymentType === "PAYATSTORE" ? "Pay at Store" : "Credit Card" } />
            </div>

            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <DetailsItem
                name="Contact Information"
                value1={data.customer.phone}
                value2={data.customer.email}
              />
            </div>
          </div>

          <h6 style={{ alignSelf: "start" }}>Order Summary</h6>
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th
                  scope="col"
                  style={{ backgroundColor: "#ED4E53", color: "white" }}
                >
                  Item
                </th>
                <th
                  scope="col"
                  style={{ backgroundColor: "#ED4E53", color: "white" }}
                >
                  Quantity
                </th>
                <th
                  scope="col"
                  style={{ backgroundColor: "#ED4E53", color: "white" }}
                >
                  Price
                </th>
              </tr>
            </thead>
            <tbody>
              {data.order.items.map((item) => (
                <tr>
                  <th scope="row">{item.shortDescription}</th>
                  <td>{item.quantity}x</td>
                  <td>${item.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <table class="table" style={{ width: "25%", alignSelf: "end" }}>
            <thead class="thead-dark">
              <tr>
                <th scope="col">Subtotal</th>
                <th style={{ textAlign: "end" }}>${data.order.subtotal}</th>
              </tr>
            </thead>
          </table>
          <table class="table" style={{ width: "25%", alignSelf: "end" }}>
            <thead class="thead-dark">
              <tr>
                <th scope="col">Tax</th>
                <th style={{ textAlign: "end" }}>$ { Number.parseFloat( data.order.tax ).toFixed(2)  }</th>
              </tr>
            </thead>
          </table>
          <table class="table" style={{ width: "25%", alignSelf: "end" }}>
            <thead class="thead-dark">
              <tr>
                <th
                  scope="col"
                  style={{ backgroundColor: "#ED4E53", color: "white" }}
                >
                  Total
                </th>
                <th
                  scope="row"
                  style={{
                    textAlign: "end",
                    backgroundColor: "#ED4E53",
                    color: "white",
                  }}
                >
                  ${ Number.parseFloat( data.order.total ).toFixed(2) }
                </th>
              </tr>
            </thead>
          </table>
          {data.payment.formOfPaymentType !== "PAYATSTORE" && (
            <div className="row row-gap-1" style={{ width: "100%" }}>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <AddressItem
                  name="Street Address"
                  address1={data.customer.streetAddress.address1}
                  address2={data.customer.streetAddress.address2}
                  city={data.customer.streetAddress.city}
                  state={data.customer.streetAddress.state}
                  postalCode={data.customer.streetAddress.postalCode}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <AddressItem
                  name="Billing Address"
                  address1={data.customer.billingAddress.address1}
                  address2={data.customer.billingAddress.address2}
                  city={data.customer.billingAddress.city}
                  state={data.customer.billingAddress.state}
                  postalCode={data.customer.billingAddress.postalCode}
                />
              </div>
            </div>
          )}
          <button
            type="submit"
            className="btn-custom primary btn-block mt-4"
            onClick={onSubmit}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

const DetailsItem = ({ name, value1, value2 }) => {
  return (
    <div class="d-flex flex-column align-items-center">
      <h6 style={{ textAlign: "left", marginBottom: "0px" }}>{name}</h6>
      <div class="d-flex flex-column align-items-center">
        <span>{value1}</span>
        {value2 !== null && <span>{value2}</span>}
      </div>
    </div>
  );
};

const AddressItem = ({ name, address1, address2, city, state, postalCode }) => {
  return (
    <div
      class="d-flex flex-column align-items-start"
      style={{
        backgroundColor: "white",
        padding: "10px",
        borderRadius: "4px",
        minWidth: "30%",
      }}
    >
      <h6 style={{ textAlign: "left", marginBottom: "0px" }}>{name}:</h6>
      <div class="d-flex flex-column align-items-start">
        <span>
          {address1}, {address2}
        </span>
        <span>
          {city}, {state}, {postalCode}
        </span>
      </div>
    </div>
  );
};
