import { useGetContent } from "./useGetContent";

export const Content = () => {
  const { data, isLoading } = useGetContent("privacy_policy");

  return (
    <div className="section">
      <div className="container">
        <div className="row">
          <div className="col-lg-8">
            {isLoading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              <div className="post-content">
                <h3>{data?.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: data?.content }} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
