import { FC, ReactNode } from "react";

interface DetailsItemProps {
  name: ReactNode;
  value: ReactNode;
}

export const DetailsItem: FC<DetailsItemProps> = ({ name, value }) => {
  return (
    <>
      <label style={{ textAlign: "left", marginBottom: "0px" }}>{name}</label>
      <div style={{ minHeight: "82px" }}>
        <span>{value}</span>
      </div>
    </>
  );
};
