import { useMutation } from "@tanstack/react-query";
import { CreateOrderInput } from "../../../types";
import { createOrder } from "../../../api/order/createOrder";

export const useCreateOrder = () => {
  return useMutation<any, string, CreateOrderInput>(
    ["create-order"],
    createOrder
  );
};
