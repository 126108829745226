import { Component } from "react";
import location from "../../../data/restaurant.json";

class Content extends Component {
  render() {
    return (
      <div className="section">
        <div className="container locations-wrapper">
          {location.map((item, i) => (
            <div key={i} className="location-item">
              <div className="row">
                <div className="col-md-6">
                  <div className="location-item-inner">
                    <img
                      src={process.env.PUBLIC_URL + "/" + item.img}
                      alt={item.title}
                    />
                    <div className="location-desc">
                      <h3>{item.title}</h3>
                      <p>{item.brancetype}</p>
                    </div>
                    <div className="location-info">
                      <div className="row">
                        <div className="col-6">
                          {item.location.map((item, i) => (
                            <span key={i}>{item}</span>
                          ))}
                        </div>
                        <div className="col-6">
                          <span>
                            {" "}
                            Give us a call:{" "}
                            <a rel={"external"} href={"tel:" + item.contactno}>
                              {item.contactno}
                            </a>{" "}
                          </span>
                          <span>
                            {" "}
                            Email Us:{" "}
                            <a rel={"external"} href={"mailto:" + item.emailid}>
                              {item.emailid}
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="ct-contact-map-wrapper">
                    <iframe
                      title="Ninoz Pizza & Pasta"
                      loading="lazy"
                      width="100%"
                      height="100%"
                      frameborder="0"
                      scrolling="no"
                      marginheight="0"
                      marginwidth="0"
                      src="https://maps.google.com/maps?width=100%25&amp;height=100%25&amp;hl=en&amp;q=500%20N%20US%20Hwy%20377%20Ste%20F,%20Roanoke,%20TX%2076262,%20United%20States+(Ninoz%20Pizza%20&amp;%20Pasta)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                    >
                      <a href="https://www.gps.ie/">gps trackers</a>
                    </iframe>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Content;
