import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "../../../hooks/useRedux/useRedux";
import { actions } from "../../../redux/auth/auth.slice";
import { useNavigate } from "react-router";
import { signOutCustomer } from "../../../api/auth/signOutCustomer";

export const useSignOutCustomer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return useMutation<any, string>(["sign-out-customer"], signOutCustomer, {
    onSuccess: () => {
      navigate("/");
      dispatch(actions.signOut());
    },
  });
};
